/* eslint-disable array-callback-return */
import actions from "./actions";
import moment from "moment";

const initialState = {
  clinics: [

  ],
  patients: [],
  questions: [

  ],
  answers: [

  ],
  editHistory: [

  ],
  notes: [

  ],
  highlights: [
    
  ],
  tableNotes: [

  ]
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload };
    }
    case actions.SAVE_CLINIC: {
      return Object.assign({}, state, { clinics: state.clinics.concat(action.payload) })
    }
    case actions.UPDATE_CLINIC_LIST: {
      return Object.assign({}, state, { clinics: action.payload })
    }
    case actions.SAVE_INITIAL_PROGRESS: {
      const patientsCopy = [ ...state.patients ]

      const hasDuplicate = patientsCopy.find(patient => patient.patientId === action.payload.patientId)

      if (hasDuplicate) {
        return state
      } else {
        return { ...state, patients: [ ...state.patients, action.payload ] }
      }
    }
    case actions.SAVE_QUESTION: {
      const {
        patientId,
        questionId,
        versionId,
        title,
        description,
        options,
        questionType,
        required,
        section,
      } = action.payload;

      const patientsCopy = [ ...state.patients ]

      patientsCopy
        .filter(item => {
          return item.patientId === patientId
        })
        .map(item => {
          return item.questionSet.push({
            questionId,
            versionId,
          });

        })
        
      const questionsCopy = [ ...state.questions ]

      questionsCopy.push({
        questionId,
        versionId,
        title,
        description,
        options,
        questionType,
        required,
        section,
        answers: [],
        notes: []
      })

      return Object.assign({}, state, { patients: patientsCopy, questions: questionsCopy })
    }
    case actions.SAVE_ANSWER: {
      const { 
        answer, 
        patientId, 
        _patientId,
        answeredBy, 
        questionId,
        answerId,
        createdAt,
        updatedAt,
        id
      } = action.payload;


      const patientsCopy = [ ...state.patients ]
      const answersCopy = [ ...state.answers ]

      patientsCopy.map(item => {
        if (item.patientId === patientId) {
          
          item.patientQuestionSet.map(question => {
            if (question.questionId === questionId) {

              if (question.answers.length === 0) {

                question.answers.push(id)
              } 

              const hasDuplicateAnswer = question.answers.find(qAndA => {
                return qAndA === answerId
              })

              if (!hasDuplicateAnswer) {
                question.answers.push(id)
              }

            }

            return false
          })

          item.gnQuestionSet.map(question => {
            if (question.questionId === questionId) {

              if (question.answers.length === 0) {

                question.answers.push(id)
              } 

              const hasDuplicateAnswer = question.answers.find(qAndA => {
                return qAndA === answerId
              })

              if (!hasDuplicateAnswer) {
                question.answers.push(id)
              }

            }

            return false
          })
        }

        return false
      });

      if (answersCopy.length === 0) {
        answersCopy.push({
          answerId,
          answer,
          answeredBy,
          createdAt,
          updatedAt,
          _patientId: _patientId,
          _id: answerId,
          id: answerId
        })

      } else {
        const hasDuplicate = answersCopy
          .find(a => {
            return a.answerId === answerId
          })

        if (hasDuplicate) {
          answersCopy 
            .filter(singleAnswer => {
              return singleAnswer.answerId === answerId
            })
            .map(singleAnswer => {
              singleAnswer.answer = answer
              singleAnswer.updatedAt = updatedAt

              return false
            })

        } else {
          answersCopy.push({
            answerId,
            answer,
            answeredBy,
            createdAt,
            updatedAt,
            _patientId,
            _id: id,
            id,
          })
        }

      }

      return Object.assign({}, state, { answers: answersCopy })
    }
    case actions.SUBMIT_WITH_TIMESTAMP: {
      const { patientId, submitTimestamp, dateVerified, verifiedBy, versionId } = action.payload;

      const patientsCopy = [ ...state.patients ]
      const editHistoryCopy = [ ...state.editHistory ]

      patientsCopy
        .filter(patient => {
          return patient.patientId === patientId && patient.questionSet.length !== 0
        })
        .map(patient => {
          patient.submitTimestamp = submitTimestamp
          patient.dateVerified = dateVerified
          patient.verifiedBy = verifiedBy

          patient.editHistory.push(versionId)

          editHistoryCopy.push({
            id: patient.id,
            dateVerified,
            verifiedBy,
            versionId,
            editedQuestion: "",
          })

          return false
        })

      return Object.assign({}, state, { patients: patientsCopy, editHistory: editHistoryCopy });
    }
    case actions.DELETE_RESPONSE: {
      return Object.assign({}, state, { patients: state.patients.filter(item => {
        return item.submitTimestamp !== undefined || item.questionSet.length > 0
      })})
    }
    case actions.SAVE_GROUP_NUMBER: {
      const { groupNumberId, patientId } = action.payload;

      const patientsCopy = [ ...state.patients ]

      if(groupNumberId !== undefined && groupNumberId !== "") {
        patientsCopy
          .filter(patient => { 
            return patient.patientId === patientId
          })
          .map(patient =>  {
            patient.groupNumberId = groupNumberId
          })
      }

      return  Object.assign({}, state, { patients: patientsCopy })

    }
    case actions.SAVE_PATIENT_GROUP_NUMBER: {
      const {
        patientId,
        questionId,
        title,
        description,
        options,
        questionType,
        required,
        section,

        versionId,
        answerId,
        answer,
        answeredBy,
        answeredById,
        timestamp,
      } = action.payload;

      const patientsCopy = [...state.patients]

      patientsCopy
        .filter(item => {
          return item.patientId === patientId;
        })
        .map(item => {

          const hasDuplicateQuestion = item.questionSet.some(question => {
            return question.versionId === versionId
          })

          if(!hasDuplicateQuestion) {

            return item.questionSet.push({
              questionId,
              versionId,
            });
          }
        })

      const questionsCopy = [ ...state.questions ]

      let hasDuplicateQuestion = questionsCopy.some(question => {
        return question.questionId === questionId && question.versionId === versionId
      })

      if (!hasDuplicateQuestion) {
        questionsCopy.push({
          questionId,
          versionId,
          title,
          description,
          options,
          questionType,
          required,
          section,
          answers: [],
          notes: []
        })
      }

      // update the array of answer ids for that particular question
      questionsCopy
        .filter(question => {
          return question.questionId === questionId && question.versionId === versionId
        })
        .map(question => {

          let hasDuplicateId = question.answers.some(id => {
            return id === answerId
          })

          if (!hasDuplicateId) {
            return question.answers.push(answerId)
          }
        })

      const answersCopy = [ ...state.answers ]

      // identify if the answer to be inserted already exist
      let hasDuplicateAnswer = answersCopy.some(answers => {
        return answers.answerId === answerId
      });

      if (hasDuplicateAnswer) {
        answersCopy.find(item => {
          if (item.answerId === answerId) {
            if (questionType === "checkbox") {
              let uniqueChars = [...new Set(answer)];
              item.answer = uniqueChars;
            } else {
              item.answer = answer
            }

            item.timestamp = timestamp;
          }
        });
  
      } else {
        const answerObject = {
          answerId,
          answer,
          answeredBy,
          answeredById,
          timestamp
        };
        answersCopy.push(answerObject)
      }

      // update the submit timestamp evertime new changes are submitted
      patientsCopy
        .filter(item => {
          return item.patientId === patientId;
        })
        .map(item => {
          item.submitTimestamp = moment().format()
        })
    
      return Object.assign({}, state, { questions: questionsCopy, answers: answersCopy });
    }
	  case actions.SAVE_NOTES: {

      const {
        notes,
        notesId,
        questionId,
        writtenBy,
        updatedAt,
        patientId,
      } = action.payload;

      const patientsCopy = [ ...state.patients ]
      const notesCopy = [ ...state.notes ]

      patientsCopy
        .filter(patient => {
          return patient.patientId === patientId
        })
        .map(patient => {

          const isPatientNotes = patient.patientQuestionSet
            .find(question => {
              return question.questionId === questionId
            })
          
          if (isPatientNotes !== undefined) {

            patient.patientQuestionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {

                if (question.notes.length === 0) {
                  question.notes.push(notesId)
                } 
                
                const hasDuplicateNotes = question.notes.find(id => {
                  return id === notesId
                })
    
                if (!hasDuplicateNotes) {
                  question.notes.push(notesId)
                  } 
                return false
              })
          
          } else {
            patient.gnQuestionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {

                if (question.notes.length === 0) {
                  question.notes.push(notesId)
                } 
                
                const hasDuplicateNotes = question.notes.find(id => {
                  return id === notesId
                })
    
                if (!hasDuplicateNotes) {
                  question.notes.push(notesId)
                }
    
                return false
              })

          }


          return patient
        })

      if (notesCopy.length === 0) {
        notesCopy.push(action.payload)

      } else {
        const hasDuplicate = notesCopy
          .find(a => {
            return a.notesId === notesId
          })

        if (hasDuplicate) {

          notesCopy 
            .filter(singleNote => {
              return singleNote.notesId === notesId
            })
            .map(singleNote => {
              singleNote.notes = notes           
              singleNote.writtenBy = writtenBy
              singleNote.updatedAt = updatedAt 

              return singleNote
            })

        } else {
          notesCopy.push(action.payload)
        }

      }

      return Object.assign({}, state, { patients: patientsCopy, notes: notesCopy });
    }
    case actions.SAVE_HIGHLIGHTS: {

      const {
        highlight,
        highlightId,
        questionId,
        writtenBy,
        updatedAt,
        patientId,
      } = action.payload;

      const patientsCopy = [ ...state.patients ]
      const highlightsCopy = [ ...state.highlights ]

      patientsCopy
        .filter(patient => {
          return patient.patientId === patientId
        })
        .map(patient => {

          const isPatientHighlight = patient.patientQuestionSet
            .find(question => {
              return question.questionId === questionId
            })
          
          if (isPatientHighlight !== undefined) {

            patient.patientQuestionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {

                if (question.highlights.length === 0) {
                  question.highlights.push(highlightId)
                } 
                
                const hasDuplicateHighlight = question.highlights.find(id => {
                  return id === highlightId
                })
    
                if (!hasDuplicateHighlight) {
                  question.highlights.push(highlightId)
                  } 
                return false
              })
          
          } else {
            patient.gnQuestionSet
              .filter(question => {
                return question.questionId === questionId
              })
              .map(question => {

                if (question.highlights.length === 0) {
                  question.highlights.push(highlightId)
                } 
                
                const hasDuplicateHighlight = question.highlights.find(id => {
                  return id === highlightId
                })
    
                if (!hasDuplicateHighlight) {
                  question.highlights.push(highlightId)
                }
    
                return false
              })

          }


          return patient
        })

      if (highlightsCopy.length === 0) {
        highlightsCopy.push(action.payload)

      } else {
        const hasDuplicate = highlightsCopy
          .find(a => {
            return a.highlightId === highlightId
          })

        if (hasDuplicate) {

          highlightsCopy 
            .filter(item => {
              return item.highlightId === highlightId
            })
            .map(item => {
              item.highlight = highlight        
              item.writtenBy = writtenBy
              item.updatedAt = updatedAt 

              return item
            })

        } else {
          highlightsCopy.push(action.payload)
        }

      }

      return Object.assign({}, state, { patients: patientsCopy, highlights: highlightsCopy });
    }
    case actions.FETCH_ANSWER_LIST: {
      const answersCopy = [ ...state.answers ];

      action.payload
        .map(singleAnswer => {
          
          const duplicate = answersCopy 
            .find(existingAnswers => {
              return singleAnswer.answerId === existingAnswers.answerId
            })

          if(!duplicate) {
            answersCopy.push(singleAnswer)
          }
        })

      return Object.assign({}, state, { answers: answersCopy })
    }
    case actions.FETCH_NOTES_LIST: {
      // //temporary using _id for answerId
      // const notesList = action.payload.map(item => {
      //   return { ...item, notesId: item._id }; 
      // });
      // //EO temporary using _id for answerId
      
      const notesCopy = [ ...state.notes ];

      action.payload
        .map(singleNote => {

          const hasDuplicate = state.notes
            .some(note => {
              return note.id === singleNote.id
            })

          if (!hasDuplicate) {
            notesCopy.push(singleNote)
          }
        });

      return Object.assign({}, state, { notes: notesCopy });
    }
    case actions.FETCH_TABLE_NOTES_LIST: {
      // //temporary using _id for answerId
      // const notesList = action.payload.map(item => {
      //   return { ...item, notesId: item._id }; 
      // });
      // //EO temporary using _id for answerId
      const tableNotesCopy = [ ...state.tableNotes ];

      action.payload
        .map(singleTableNote => {

          const hasDuplicate = state.tableNotes
            .some(tableNote => {
              return tableNote.id === singleTableNote.id
            })

          if (!hasDuplicate) {
            tableNotesCopy.push(singleTableNote)
          }
        })

      return Object.assign({}, state, { tableNotes: tableNotesCopy });
    }
    case actions.FETCH_HIGHLIGHT_LIST: {
      // //temporary using _id for answerId
      // const notesList = action.payload.map(item => {
      //   return { ...item, notesId: item._id }; 
      // });
      // //EO temporary using _id for answerId
      
      const highlightsCopy = [ ...state.highlights, ...action.payload ];

      return Object.assign({}, state, { highlights: highlightsCopy })
    }
    case actions.ADD_PATIENT: {
      const patientCopy = [ ...state.patients ];

      const duplicate = patientCopy
        .find(patient => {
          return patient.patientId === action.payload.patientId
        })

      if(!duplicate) {
        patientCopy.push(action.payload)
      }

      return Object.assign({}, state, { patients: patientCopy })
    }
    case actions.UPDATE_PATIENT_GN: {
      const patientCopy = [ ...state.patients ];

      patientCopy
        .filter(patient => {
          return patient.patientId !== action.payload.patientId
        })

      return Object.assign({}, state, { patients: [ action.payload ] })
    }
    case actions.SAVE_TABLE_NOTES: {

      const {
        tableNote,
        tableNoteId,
        questionId,
        writtenBy,
        updatedAt,
        patientId,
      } = action.payload;

      const patientsCopy = [ ...state.patients ]
      const tableNotesCopy = [ ...state.tableNotes ]

      patientsCopy
        .filter(patient => {
          return patient.patientId === patientId
        })
        .map(patient => {

          patient.gnQuestionSet
            .filter(question => {
              return question.questionId === questionId
            })
            .map(question => {

              if (question.tableNotes.length === 0) {
                question.tableNotes.push(tableNoteId)
              } 
              
              const hasDuplicateTableNotes = question.tableNotes.find(id => {
                return id === tableNoteId
              })
  
              if (!hasDuplicateTableNotes) {
                question.tableNotes.push(tableNoteId)
              }
  
              return false
            })

          return patient
        })

      if (tableNotesCopy.length === 0) {
        tableNotesCopy.push(action.payload)
      } else {
        const hasDuplicate = tableNotesCopy
          .find(a => {
            return a.tableNoteId === tableNoteId
          })

        if (hasDuplicate) {

          tableNotesCopy 
            .filter(singleTableNote => {
              return singleTableNote.tableNoteId === tableNoteId
            })
            .map(singleTableNote => {
              singleTableNote.tableNote = tableNote           
              singleTableNote.writtenBy = writtenBy
              singleTableNote.updatedAt = updatedAt 

              return singleTableNote
            })

        } else {
          tableNotesCopy.push(action.payload)
        }

      }

      return Object.assign({}, state, { patients: patientsCopy, tableNotes: tableNotesCopy });
    }
    default:
      return state;
  }
}
