import { useSelector } from "react-redux";
import moment from "moment";
import tzMoment from "moment-timezone";
import { isEqual } from "lodash";
import {
  MULTIPLE_CHOICE,
  CHECKBOX_GRID,
  CHECKBOX,
  DROPDOWN,
  MULTIPLE_CHOICE_GRID,
  SECTION,
  FORMS,
  CLINICS,
  INSURANCES,
  // PATIENT,
  // GN_HISTORY,
  // ANSWER,
  // NOTES,
  // GROUP_NUMBER,
  // QUESTION,
  // EDIT_HISTORY,
  TABLE,
  OTHER,
} from "../constants/KeyConstants";
import TableApi from "../sdk/api/Table";
import ClinicsApi from "../sdk/api/Clinics";
import axiosInstance from "../sdk/api/axios-instance-api";
import { IN_NETWORK_ABBR, OUT_NETWORK, IN_NETWORK, OUT_NETWORK_ABBR } from "../constants/Constants";
import { v4 as uuidv4 } from 'uuid';

export function generateId(prepend) {
  let randomized = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < 5; i++) {
    randomized += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  return prepend + "_" + Date.now() + randomized;
}

export function findIndexById(array, id) {
  return array.map(x => x.id).indexOf(id);
}

export function sortByString(array = [], sortBy = "") {
  if (Array.isArray(array) && typeof sortBy === "string") {
    const sortedResults = array.sort((a, b) => {
      if (a[sortBy] !== undefined && b[sortBy] !== undefined) {
        return a[sortBy].localeCompare(b[sortBy]);
      }

      return false;
    });

    return sortedResults;
  } else {
    return false;
  }
}

export function recentOpened(createdAt, updatedAt) {
  const result = moment(createdAt).isBefore(createdAt)
    ? "Created a few moments ago"
    : moment(updatedAt).isBefore(updatedAt)
    ? "Updated a few moments ago"
    : moment(createdAt).isBefore(updatedAt)
    ? `Updated ${moment(updatedAt).fromNow()}`
    : `Created ${moment(createdAt).fromNow()}`;

  return result;
}

export function customTimestampInfo(createdAt, updatedAt) {
  const result = moment(createdAt).isBefore(updatedAt)
    ? `${tzMoment(updatedAt).tz("America/Los_Angeles").format("LLL")}`
    : `${tzMoment(createdAt).tz("America/Los_Angeles").format("LLL")}`;

  return result;
}

export function moveArrayItem(array, fromIndex, toIndex) {
  const arrayCopy = [...array];
  const element = arrayCopy[fromIndex];
  arrayCopy.splice(fromIndex, 1);
  arrayCopy.splice(toIndex, 0, element);

  return [...arrayCopy];
}

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function errorMessage(apiName, error) {
  return false;
}

//Finds a value inside an array
export function findInArray(valueToFind = "", findIn = [], property = "id") {
  //Cannot find property of object like question.questionId with 'property argument'
  const currentValue = findIn.find(item => {
    return item[property] === valueToFind;
  });

  return currentValue;
}

export function IsAdmin() {
  const user = useSelector(state => state.user);
  return user.role === "admin";
}

//Gets the last item in the array
export function lastItem(array = []) {
  if (Array.isArray(array)) {
    return array[array.length - 1];
  }

  return false;
}

export function verifyItem(status = [], userList = []) {
  const check = moment().subtract(6, "months");
  let users = [];
  let verificationCount = 0;

  const filtered = status
    .filter(x => moment(x.createdAt).isAfter(check))
    .reverse(); //.reverse() to start from the latest

  filtered.map(item => {
    if (users.includes(item.userId)) {
      return true; //get the latest answer only from a user
    }

    users.push(item.userId); //push the id to array, to prevent other answers

    if (item.verified) {
      verificationCount = verificationCount + 1;
    }

    return true;
  });

  //Get user names
  let verifiers = [];
  if (userList.length > 1) {
    verifiers = users.map(user => {
      const findUser = userList.find(x => x.id === user);
      return findUser === undefined ? "You" : findUser.fullname;
    });
  }

  return {
    verified: verificationCount > 2,
    verifiers,
  };
}

export async function generateUniqueId(page = "clinics", type = "answers") {
  //currently thinking of a more apprioriate term for the second params

  // const POSSIBLE_COLLECTION = [CLINICS, PATIENT, GN_HISTORY, ANSWER, NOTES, INSURANCES, GROUP_NUMBER, FORMS, QUESTION, EDIT_HISTORY, TABLE];
  const POSSIBLE_PAGE = [CLINICS, INSURANCES, FORMS];

  if (!POSSIBLE_PAGE.includes(page)) {
    return false;
  }

  const newId = await axiosInstance().post(`/${page}/generateId`, {
    type,
  });

  let id;

  switch (
    type //tmp api must be refactored to return generic id
  ) {
    case "clinics":
      id = "clinicId";
      break;
    case "patient":
      id = "patientId";
      break;
    case "gnHistory":
      id = "gnHistoryId";
      break;
    case "answers":
      id = "answerId";
      break;
    case "notes":
      id = "notesId";
      break;
    case "insurances":
      id = "insuranceCoId";
      break;
    case "groupNumber":
      id = "groupNumberId";
      break;
    case "highlights":
      id = "highlightId";
      break;
    case "tableNote":
      id = "tableNoteId";
      break;
    case "file":
      id = "fileId";
      break;
    default:
      break;
  }

  return newId.data[id];
}

export async function tableAnswerChecker({
  questionType,
  required,
  tableId,
  answer,
}) {
  let table = {};
  const getTable = await TableApi.GetTable(tableId);

  if (getTable) {
    table = getTable.table.table;
  }

  // check if the table has any answer
  if (questionType === "table" && required && Object.keys(table).length > 0) {
    const arrayChecker = [];

    Object.keys(table).map(key => {
      table[key].map((cell, index) => {
        if (cell.questionType !== "text") {
          answer[key][index] !== ""
            ? arrayChecker.push(true)
            : arrayChecker.push(false);
        }

        return false;
      });

      return false;
    });

    // if arrayChecker has a true value, that means it has an answer
    if (arrayChecker.includes(true)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function tablePatientDependentChecker({ questionType, table = {} }) {
  if (questionType === "table") {
    if (Object.keys(table).length > 0) {
      let patientDependentCount = 0;

      Object.keys(table).map(key => {
        table[key].map((cell, index) => {
          if (cell.dependent === "Patient") {
            patientDependentCount++;
          }

          return false;
        });

        return false;
      });

      // if arrayChecker has a true value, that means it has an answer
      if (patientDependentCount > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateQuestionSet(questionType, answer) {
  //Validate if Blank
  switch (questionType) {
    case CHECKBOX:
      if (answer.value.length === 0 || answer.value === undefined) {
        return false;
      }
      break;
    case CHECKBOX_GRID:
      let checked = 0;
      Object.keys(answer).map(item => {
        return answer[item].map(isChecked =>
          isChecked ? (checked = checked + 1) : checked
        );
      });
      if (checked === 0) {
        return false;
      }
      break;
    default:
      if (answer === undefined || answer === null || answer === "") {
        return false;
      }
      break;
  }

  return true;
}

//Validate Clinic Data before submitting
export async function validateClinicInfo(data) {
  const clinicId = data.id ? data.id : false;
  const { clinics } = await ClinicsApi.GetClinicList();

  const hasSameName = data.name
    ? clinics.map(clinicItem => {
        return clinicItem.clinicName.toLowerCase() ===
          data.name.toLowerCase() && clinicItem.clinicId !== clinicId
          ? true
          : false;
      })
    : null;

  let emptyFields = 0;

  Object.keys(data).map(key =>
    data[key] === "" || data[key] === undefined ? emptyFields++ : emptyFields
  );

  if (emptyFields > 0) {
    return {
      valid: false,
      message: "Please fill up all the fields.",
    };
  }

  if (hasSameName.includes(true)) {
    return {
      valid: false,
      message: `The name of the Clinic (${data.name}) already exists.`,
    };
  }

  return {
    valid: true,
  };
}

//Validate Clinic Data before submitting
export async function validateInsuranceInfo(data) {
  const { id, insuranceList, name } = data;
  const insuranceCoId = id ? id : false;

  const hasSameName = name
    ? insuranceList.map(insuranceItem => {
        return insuranceItem.insuranceCo.toLowerCase() === name.toLowerCase() &&
          insuranceItem.insuranceCoId !== insuranceCoId
          ? true
          : false;
      })
    : null;

  let emptyFields = 0;

  Object.keys(data).map(key =>
    data[key] === "" || data[key] === undefined ? emptyFields++ : emptyFields
  );

  if (emptyFields > 0) {
    return {
      valid: false,
      message: "Please fill up all the fields.",
    };
  }

  if (hasSameName.includes(true)) {
    return {
      valid: false,
      message: `The name of the insurance company (${name}) already exists.`,
    };
  }

  return {
    valid: true,
  };
}

// function that will divide the edit history to smaller chunks
export function chunkArray(myArray, chunkSize) {
  let index = 0;
  const arrayLength = myArray !== undefined ? myArray.length : 1;
  const tempArray = [];

  if (myArray !== undefined) {
    for (index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = myArray.slice(index, index + chunkSize);
      tempArray.push(myChunk);
    }
  }
  return tempArray;
}

export function getAuthor(users, userId, item) {
  let authorId;

  if (item.answeredBy !== undefined) {
    authorId = item.answeredBy;
  } else if (item.writtenBy !== undefined) {
    authorId = item.writtenBy;
  } else if (item.editedBy !== undefined) {
    authorId = item.editedBy;
  } else if (item.highlightedBy !== undefined) {
    authorId = item.highlightedBy;
  } else if (item._gnHistoryId !== undefined) {
    //For GN Archive
    authorId = item._gnHistoryId.answeredBy;
  } else if (item === userId) {
    authorId = item;
  } else if (item.userId !== undefined) {
    authorId = item.userId;
  } else if (
    item !== null && 
    item !== undefined && 
    item !== "" && 
    typeof item === "string"
  ) {
    authorId = item;
  } else {
    authorId = null;
  }

  const findUser = users.map(x => x.id).indexOf(authorId);
  let author = "[Unknown User]";

  if (findUser === -1 && authorId === userId) {
    author = <b>You</b>;
  }

  if (findUser > -1) {
    author = <b>{users[findUser].fullname}</b>;
  }

  return author;
}

export function getUserNameV1(users, currentUserId, userId) {

  const findUser = users.map(x => x.id).indexOf(userId);

  if (findUser === -1 && currentUserId === userId) {
    return 'you';  
  }

  if (findUser !== -1) {
    return users[findUser].fullname;
  }

  return 'an unknown user';
}

export function getUserNameV2(users, currentUserId, currentUserName, userId) {

  const findUser = users.map(x => x.id).indexOf(userId);

  if (findUser === -1 && currentUserId === userId) {
    return `${currentUserName}`;  
  }

  if (findUser !== -1) {
    return users[findUser].fullname;
  }

  return 'Unknown user';
}

export function getUserNameV3(users, currentUserId, userId) {
  const findUser = users.map(x => x.id).indexOf(userId);

  if (findUser === -1 && currentUserId === userId) {
    return 'You';  
  }

  if (findUser !== -1) {
    return users[findUser].fullname;
  }

  return 'An unknown user';
}

export function getTimelineDescription({
  item,
  questionDetails,
  users,
  userId,
  showTitle = false,
}) {
  const { title, questionType } = questionDetails;
  const author = getAuthor(users, userId, item);
  const itemTitle = title ? title : "[Untitled]";
  const titlePhrase = showTitle ? `in ${itemTitle}` : "";

  if (item.notes !== undefined) {
    // IF GN NOTES
    if(questionType === "groupNumberNotes") {
      return (
        <p>
          {author} updated the group number note to{" "}
          <strong>"{item.notes}".</strong>
        </p>
      )
    }

    // IF QUESTION NOTES
    return (
      <p>
        {author} updated the note {titlePhrase} to{" "}
        <strong>"{item.notes}".</strong>
      </p>
    );
  } else if (item.highlight !== undefined) {
    //IF HIGHLIGHT
    let action = "removed the highlights";

    if (item.highlight !== false) {
      action =
        questionType === TABLE
          ? "modified the highlights"
          : "added the highlights.";
    }

    return (
      <p>
        {author} {action}{" "}
        {showTitle ? (
          <i>
            in {questionDetails.title} {questionType === TABLE ? "table" : ""}
          </i>
        ) : (
          "of this question set."
        )}
      </p>
    );
  } else if (questionType === "changeGroupNumber") {
    return (
      <p>
        {author} changed the group number to <strong>{item.answer}</strong>
      </p>
    );
  } else if (item.tableNote !== undefined) {
    return (
      <p>
        {author} updated the procedure note.
      </p>
    );
  } else {
    //IF ANSWERS
    switch (questionType) {
      case TABLE:
        if (item.notes !== undefined) {
          return (
            <>
              {author} updated the notes {titlePhrase} to{" "}
              <strong>"{item.notes}".</strong>
            </>
          );
        } else {
          return showTitle ? (
            <p>
              {author} updated the answer on table <i>{questionDetails.title}</i>.
            </p>
          ) : (
            <p>{author} updated the table answer.</p>
          );
        }
      case SECTION:
        if (showTitle) {
          return (
            <p>
              {item.answeredBy} updated the note {titlePhrase} to{" "}
              <strong>"{item.notes}".</strong>
            </p>
          );
        } else {
          return (
            <p>
              {item.answeredBy} updated the note to{" "}
              <strong>"{item.notes}".</strong>
            </p>
          );
        }
      case CHECKBOX:
        return (
          <>
            {author} updated the answer {titlePhrase} to{" "}
            {item.answer.value.map((itemAnswer, i) =>
              i + 1 < item.answer.value.length ? (
                itemAnswer === OTHER ? (
                  <div
                    key={i}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <strong>
                      "{itemAnswer} - {item.answer.othersValue}"
                    </strong>
                    {i + 2 >= item.answer.value.length ? `` : `, `}
                  </div>
                ) : (
                  <div
                    key={i}
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <strong>"{itemAnswer}"</strong>
                    {i + 2 >= item.answer.value.length ? ` ` : `, `}
                  </div>
                )
              ) : itemAnswer === "Other..." ? (
                <div
                  key={i}
                  style={{
                    display: "inline-block",
                    whiteSpace: "pre",
                  }}
                >
                  {item.answer.value.length === 1 ? ` ` : ` and `}{" "}
                  <strong>
                    "{itemAnswer} - {item.answer.othersValue}"
                  </strong>
                </div>
              ) : (
                <div
                  key={i}
                  style={{
                    display: "inline-block",
                    whiteSpace: "pre",
                  }}
                >
                  {item.answer.value.length === 1 ? ` ` : ` and `}{" "}
                  <strong>"{itemAnswer}"</strong>
                </div>
              )
            )}
            .
          </>
        );
      case CHECKBOX_GRID:
      case MULTIPLE_CHOICE_GRID:
        return (
          <p>
            <strong>{author}.</strong> updated the answer.
          </p>
        );
      case MULTIPLE_CHOICE:
        return (
          <>
            <p>
              {author} updated the answer to{" "}
              <strong>"{item.answer.value}".</strong>
            </p>
            {item.answer.additionalFields.length > 0
              ? item.answer.additionalFields.map((field, i) => {
                  if (field.value) {
                    const index = questionDetails.options
                      .map(x => x.value)
                      .indexOf(item.answer.value); //get option index by value
                    const afIndex = questionDetails.options[
                      index
                    ].additionalFields
                      .map(x => x.id)
                      .indexOf(field.id); //get af index by id
                    const fieldName =
                      questionDetails.options[index].additionalFields[afIndex]
                        .label; //finally get the field name
                    return (
                      <div key={i}>
                        <i>{fieldName}</i>: {field.value}
                      </div>
                    );
                  }

                  return false;
                })
              : null}
          </>
        );
      case DROPDOWN:
        return (
          <p>
            {author} updated the answer to{" "}
            <strong>"{item.answer}".</strong>
          </p>
        );
      default:
        return (
          <p>
            {author} updated the answer to{" "}
            <strong>"{item.answer}".</strong>
          </p>
        );
    }
  }
}

export function getCompletePatientInfo({ patients = [], questions = []}, clinicsList = []) {
  //This function assumes that all of the items are required and there exists in the given data.
  //Make an array of the key of the expected returned values { [object key]: [title of the question] }
  const valuesToGet = {
    insuranceInfoCategory: "Insurance Information Category",
    firstName: "First Name",
    lastName: "Last Name",
    npi: "NPI",
    dob: "DOB",
    aptDate: "Apt date",
  };

  const valuesToGetInsured = {
    firstName: "First Name",
    lastName: "Last Name",
    dob: "DOB",
    ssn: "SSN",
    idNo: "ID Number",
    employer: "Employer",
    aptDate: "Apt date",
  };

  //Map the patients
  let patientInfoList = patients.map(patient => {
    let patientInfo = {};
    let insuredsInfo = {};

    //STEP 1: Get questionIds (of valuesToGet arrays)
    let questionIds = Object.keys(valuesToGet).map(info => {
      const selectedQuestion = questions.find(question => {
        const isForThisPatient = patient.patientQuestionSet.some(
          x => x.questionId === question.questionId
        );

        let section = parseInt(question.section);

        if (question.title === "Insurance Information Category") {
          section = 1; //Manual check for Insurance Information Category to have the right section.
        }

        return (
          question.title === valuesToGet[info] &&
          question.defaultQues &&
          section === 1 &&
          isForThisPatient
        );
      });

      return selectedQuestion === undefined
        ? false
        : {
            name: info,
            id: selectedQuestion.questionId,
          };
    });

    let questionIdsInsured = Object.keys(valuesToGetInsured).map(info => {
      const selectedQuestion = questions.find(question => {
        const isForThisPatient = patient.patientQuestionSet.some(
          x => x.questionId === question.questionId
        );
        return (
          question.title === valuesToGetInsured[info] &&
          question.defaultQues &&
          parseInt(question.section) === 2 &&
          isForThisPatient
        );
      });

      return selectedQuestion === undefined
        ? false
        : {
            name: info,
            id: selectedQuestion.questionId,
          };
    });

    questionIds = questionIds.filter(x => x !== false);
    questionIdsInsured = questionIdsInsured.filter(x => x !== false);

    //Step 2: Get Answers
    questionIds.map(question => {
      const { answers } = patient.patientQuestionSet.find(
        x => x.questionId === question.id
      );
      let answerValue = "";

      if (answers.length > 0) {
        answerValue = answers[answers.length - 1].answer;
      }

      return (patientInfo = {
        ...patientInfo,
        [question.name]: answerValue,
      });
    });

    questionIdsInsured.map(question => {
      const { answers } = patient.patientQuestionSet.find(
        x => x.questionId === question.id
      );
      let answerValue = "";

      if (answers.length > 0) {
        answerValue = answers[answers.length - 1].answer;
      }

      return (insuredsInfo = {
        ...insuredsInfo,
        [question.name]: answerValue,
      });
    });

    const clinic = clinicsList.find(x => x.clinicId === patient.clinicId);

    return {
      ...patientInfo,
      patientId: patient.patientId,
      insuredsInfo: {
        ...insuredsInfo,
        claimsMailTo: patient.groupNumberId
          ? patient.insuranceCoId.address
          : "",
        electronicClaims: patient.groupNumberId
          ? patient.insuranceCoId.email
            ? patient.insuranceCoId.email
            : ""
          : "",
      },
      insuranceInfoCategory:
        patientInfo.insuranceInfoCategory !== undefined
          ? patientInfo.insuranceInfoCategory.value
          : "",
      groupNo: patient.groupNumberId !== null ?
        patient.groupNumberId.groupNumberName
        :
        "",
      insuranceCo: patient.insuranceCoId !== null ?
        patient.insuranceCoId.insuranceCo
        :
        "",
      insuranceTelNo: patient.groupNumberId
        ? patient.insuranceCoId.telephoneNo
        : "",
      taxId: clinic !== undefined ? clinic.taxId : "",
      createdAt: patient.createdAt,
      updatedAt: patient.updatedAt,
    };
  });

  return patientInfoList;
}

export function getPatientBasicInfo({ patients = [] }) {
  let patientInfoList = patients.map(patient => {

    return {
      patientId: patient.patientId,
      firstName: patient.firstName,
      lastName: patient.lastName,
      dob: patient.dob,
      insuranceInfoCategory:
        patient.insuranceInfoCategory !== undefined
          ? patient.insuranceInfoCategory
          : "",
      groupNo: patient.groupNumberId !== null ?
        patient.groupNumberId.groupNumberName
        :
        "",
      insuranceCo: patient.insuranceCoId !== null ?
        patient.insuranceCoId.insuranceCo
        :
        "",
      insuranceTelNo: patient.groupNumberId
        ? patient.insuranceCoId.telephoneNo
        : "",
      createdAt: patient.createdAt,
      updatedAt: patient.updatedAt,
    };
  });

  return patientInfoList;
}

export function getSinglePatientInfo(patient, clinicsList) {
  //This function assumes that all of the items are required and there exists in the given data.
  //Make an array of the key of the expected returned values { [object key]: [title of the question] }
  const valuesToGet = {
    insuranceInfoCategory: "Insurance Information Category",
    firstName: "First Name",
    lastName: "Last Name",
    npi: "NPI",
    dob: "DOB",
    aptDate: "Apt date",
  };

  const valuesToGetInsured = {
    firstName: "First Name",
    lastName: "Last Name",
    dob: "DOB",
    ssn: "SSN",
    idNo: "ID Number",
    employer: "Employer",
    aptDate: "Apt date",
  };

  //Map the patients

  let patientInfo = {};
  let insuredsInfo = {};

  //STEP 1: Get Patient Form Used (This is from the API, and we are reassigning the value we got to a specific variable easy access)
  let formQuestionSet = patient.questions;

  //STEP 2: Get questionIds (of valuesToGet array)
  let questionIds = Object.keys(valuesToGet).map(info => {
    const selectedQuestion = formQuestionSet.find(question => {
      const isForThisPatient = patient.patientQuestionSet.some(
        x => x.questionId === question.questionId
      );

      return (
        question.title === valuesToGet[info] &&
        question.defaultQues &&
        parseInt(question.section) === 1 &&
        isForThisPatient
      );
    });

    return selectedQuestion === undefined
      ? false
      : {
          name: info,
          id: selectedQuestion.questionId,
        };
  });

  let questionIdsInsured = Object.keys(valuesToGetInsured).map(info => {
    const selectedQuestion = formQuestionSet.find(question => {
      const isForThisPatient = patient.patientQuestionSet.some(
        x => x.questionId === question.questionId
      );
      return (
        question.title === valuesToGetInsured[info] &&
        question.defaultQues &&
        parseInt(question.section) === 2 &&
        isForThisPatient
      );
    });

    return selectedQuestion === undefined
      ? false
      : {
          name: info,
          id: selectedQuestion.questionId,
        };
  });

  questionIds = questionIds.filter(x => x !== false);
  questionIdsInsured = questionIdsInsured.filter(x => x !== false);

  //STEP 3: Get answersIds (This is from the API, we are reassigning to a variable the patient's questionList and answerList for easy access)
  let patientQuestionSet = patient.patientQuestionSet;
  let patientAnswerList = patient.patientAnswerList;

  const answerIds = questionIds.map(question => {
    const questionSet = patientQuestionSet.find(
      patientQuestion => patientQuestion.questionId === question.id
    );

    return {
      name: question.name,
      id: questionSet.answers[questionSet.answers.length - 1],
    };
  });

  const answerIdsInsured = questionIdsInsured.map(question => {
    const questionSet = patientQuestionSet.find(
      patientQuestion => patientQuestion.questionId === question.id
    );

    return {
      name: question.name,
      id: questionSet.answers[questionSet.answers.length - 1],
    };
  });

  //Step 4: Get value of answerIds
  answerIds.map(answerInfo => {
    const answer = patientAnswerList.find(x => x._id === answerInfo.id);
    return (patientInfo = {
      ...patientInfo,
      [answerInfo.name]:
        answer === undefined
          ? ""
          : answer.answer !== undefined
          ? answer.answer
          : "",
    });
  });

  answerIdsInsured.map(answerInfo => {
    const answer = patientAnswerList.find(x => x._id === answerInfo.id);
    return (insuredsInfo = {
      ...insuredsInfo,
      [answerInfo.name]: answer !== undefined ? answer.answer : "",
    });
  });

  //Step 5 Get OTHER information
  // let groupNumber = "";
  // if (patient.groupNumberId) {
  //   groupNumber = patient.insuranceCo.groupNumbers.find(
  //     x => x._groupNumberId === patient.groupnumbers._id
  //   );
  // }

  const clinic = clinicsList.find(x => x.clinicId === patient.clinicId);

  return {
    ...patientInfo,
    patientId: patient.patientId,
    insuredsInfo: {
      ...insuredsInfo,
      // claimsMailTo: patient.groupNumberId ? patient.insuranceCo.address : "",
      // electronicClaims: patient.groupNumberId
      //   ? patient.insuranceCo.email
      //     ? patient.insuranceCo.email
      //     : ""
      //   : "",
    },
    insuranceInfoCategory: patientInfo.insuranceInfoCategory.value,
    groupNo: patient.groupNumber,
    groupNoVerification: patient.groupNumberVerificationHistory,
    insuranceCo: patient.insuranceCo,
    insuranceTelNo: patient.insuranceCoTelNo,
    taxId: clinic !== undefined ? clinic.taxId : "",
    _id: patient._id,
  };
}

export function extractQuestionSets(questionSet) {
  const reworkedQuestionSet = questionSet
    .map(qs => {
      return {
        _id: qs._id,
        order: qs.order,
        ...qs._questionId,
      };
    })
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    });

  return reworkedQuestionSet;
}

export function validateEmail(enteredEmail) {
  const emailRegex = new RegExp(
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    "gm"
  ); //eslint-disable-line

  return emailRegex.test(enteredEmail);
}

export function updateTableAnswer(
  e, 
  dropdownOptions, 
  table, 
  showTextfield, 
  additionalTxtFieldAnswer, 
  questionTable, 
  disabledTableCells
) {
  const first = e.target.name.split("-")[0];
  const second = e.target.name.split("-")[1];
  const third = e.target.name.split("-")[2];
  const copy = JSON.parse(JSON.stringify(table));
  const showTextfieldCopy = JSON.parse(JSON.stringify(showTextfield));
  const disabledTableCellsCopy = JSON.parse(JSON.stringify(disabledTableCells));

  if (dropdownOptions !== undefined) {
    const dropdownCopy = [...dropdownOptions];

    if (e.target.value === "Others...") {
      showTextfieldCopy[first][second] = "Others...";
    }

    if (
      e.target.value !== "Others..." &&
      dropdownCopy
        .splice(dropdownOptions.indexOf("Others..."), 1)
        .includes(e.target.value)
    ) {
      showTextfieldCopy[first][second] = "";
    }

    if (
      e.target.value !== "Others..." &&
      dropdownCopy.filter(item => item !== "Others...").includes(e.target.value)
    ) {
      showTextfieldCopy[first][second] = "";
    }

    if (e.target.value === "Others...") {
      copy[first].splice(second, 1, {
        chosenOption: "Others...",
        answer: "",
      });
    } else if (
      showTextfieldCopy[first][second] === "Others..." &&
      Object.keys(copy)
        .map(key => {
          if (key === first) {
            if (copy[key][second].answer !== undefined) {
              return true;
            }
            return false;
          }
          return false;
        })
        .find(bool => {
          return bool === true;
        })
    ) {
      copy[first].splice(second, 1, {
        chosenOption: "Others...",
        answer: e.target.value,
      });
    } else if (
      showTextfieldCopy[first][second]?.chosenOption === "Others..." &&
      !dropdownCopy
        .splice(dropdownOptions.indexOf("Others..."), 1)
        .includes(e.target.value)
    ) {
      copy[first].splice(second, 1, {
        chosenOption: "Others...",
        answer: e.target.value,
      });
    } else {
      copy[first].splice(second, 1, e.target.value);
    }
  } else if (third !== undefined && third === "addOn") {
    copy[first][second] = additionalTxtFieldAnswer;
  } else {
    copy[first].splice(second, 1, e.target.value);
  }

  // Determine which table cell will be disabled
  // because of an automated answer
  if (
    questionTable !== undefined &&
    questionTable[first][second]?.automatedTblCell !== undefined && 
    questionTable[first][second]?.automatedTblCell !== "" && 
    questionTable[first][second]?.automatedTblCell !== "Select one" &&
    questionTable[first][second]?.automatedValue !== undefined &&
    questionTable[first][second]?.ifAnswer !== undefined && 
    questionTable[first][second]?.ifAnswer !== ""
  ) {
    const automationKey = questionTable[first][second].automatedTblCell.split("-")[0];
    const automationIndex = questionTable[first][second].automatedTblCell.split("-")[1];

    if (e.target.value === questionTable[first][second].ifAnswer) {
      copy[automationKey][automationIndex-1] = questionTable[first][second].automatedValue;
      disabledTableCellsCopy[automationKey][automationIndex-1] = true;
    } else {
      copy[automationKey][automationIndex-1] = "";
      disabledTableCellsCopy[automationKey][automationIndex-1] = false;
    }
  }

  return {
    updatedTable: copy,
    updatedTextfield: showTextfieldCopy,
    updatedDisabledTableCells: disabledTableCellsCopy
  };
}

export function convertToByteArray(input) {
  const sliceSize = 512;
  const bytes = [];

  for (let offset = 0; offset < input.length; offset += sliceSize) {
    const slice = input.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    bytes.push(byteArray);
  }

  return bytes;
}

export function addPercentOrDollarSign(table, fetchedAnswer) {
  const tableAnswer = {};

  if (Object.keys(table).length > 0 && Object.keys(fetchedAnswer).length > 0) {

    function insertPercentDollars(updatedAnswer) {
      Object.keys(table).map(tableKey => {
        tableAnswer[tableKey] = [];
  
        return table[tableKey].map((arrayItem, index) => {
          if (arrayItem.questionType === "text") {
            return tableAnswer[tableKey].push(arrayItem.text);
          }
  
          if (
            updatedAnswer[tableKey] !== undefined &&
            updatedAnswer[tableKey] !== null &&
            updatedAnswer[tableKey][index] !== null &&
            updatedAnswer[tableKey][index] !== ""
          ) {
            if (
              arrayItem.questionType === "Short Answer" &&
              arrayItem.inputType === "Number"
            ) {
              return tableAnswer[tableKey].push(
                `$${updatedAnswer[tableKey][index]}`
              );
            }
  
            if (
              arrayItem.questionType === "Short Answer" &&
              arrayItem.inputType === "Percentage"
            ) {
              return tableAnswer[tableKey].push(
                `${updatedAnswer[tableKey][index]}%`
              );
            }
          }
  
          if (
            updatedAnswer[tableKey] === undefined ||
            updatedAnswer[tableKey] === null
          ) {
            return tableAnswer[tableKey].push(table[tableKey][index].text);
          }
  
          return tableAnswer[tableKey].push(updatedAnswer[tableKey][index]);
        });
      });
    }
  
    const tableKeys = Object.keys(table).map(key => {
      return key;
    });
    const answerKeys = Object.keys(fetchedAnswer).map(key => {
      return key;
    });
  
    let isSameKeys = isEqual(tableKeys, answerKeys);
  
    let isArraySameLength =
      table[Object.keys(table)[0]].length ===
      fetchedAnswer[Object.keys(fetchedAnswer)[0]].length;
    const fetchedAnswerCopy = JSON.parse(JSON.stringify(fetchedAnswer));
  
    if (isSameKeys && isArraySameLength) {
      insertPercentDollars(fetchedAnswerCopy);
    }
  
    if (!isEqual(table, {})) {
      if (!isSameKeys) {
        Object.keys(table).map(key => {
          if (fetchedAnswerCopy[key] === undefined) {
            fetchedAnswerCopy[key] = [];
  
            table[key].map(item => {
              return fetchedAnswerCopy[key].push("");
            });
          }
  
          return false;
        });
  
        insertPercentDollars(fetchedAnswerCopy);
      }
  
      if (!isArraySameLength) {
        Object.keys(table).map(key => {
          table[key].map((item, index) => {
            if (fetchedAnswerCopy[key][index] === undefined) {
              fetchedAnswerCopy[key].push("");
            }
  
            return false;
          });
  
          return false;
        });
  
        insertPercentDollars(fetchedAnswerCopy);
      }
    }

    return tableAnswer;
  }

  return tableAnswer;
}

export function highlightMatchStructure(tableStructure, highlightData) {
  if (!isEqual(tableStructure, {})) {
    const tableKeys = Object.keys(tableStructure).map(key => {
      return key;
    });
    const highlightKeys = Object.keys(highlightData).map(key => {
      return key;
    });

    let isSameKeys = isEqual(tableKeys, highlightKeys);
    let isArraySameLength =
      tableStructure[Object.keys(tableStructure)[0]].length ===
      highlightData[Object.keys(highlightData)[0]].length;
    const highlightDataCopy = JSON.parse(JSON.stringify(highlightData));

    if (isSameKeys && isArraySameLength) {
      return highlightDataCopy;
    } else {
      if (!isSameKeys) {
        const newHighlihtData = {};
        Object.keys(tableStructure).map(key => {
          newHighlihtData[key] = [];

          if (highlightDataCopy[key] !== undefined) {
            return (newHighlihtData[key] = [...highlightDataCopy[key]]);
          }

          return tableStructure[key].map(item => {
            return newHighlihtData[key].push(false);
          });
        });

        return newHighlihtData;
      }

      if (!isArraySameLength) {
        Object.keys(tableStructure).map(key => {
          tableStructure[key].map((item, index) => {
            if (highlightDataCopy[key][index] === undefined) {
              highlightDataCopy[key].push(false);
            }

            return false;
          });

          return false;
        });

        return highlightDataCopy;
      }
    }
  }

  return highlightData;
}

export function snapshotToArray(snapshot, firebaseAuthCurrUser) {
  const arr = [];

  if (firebaseAuthCurrUser) {
    const valueArr = { ...snapshot.val() };

    Object.keys(valueArr)
      .filter(id => {
        return firebaseAuthCurrUser.uid !== id;
      })
      .map(id => {
        return arr.push({
          fullname:
            valueArr[id].fullname !== undefined
              ? valueArr[id].fullname
              : `${valueArr[id].firstName} ${valueArr[id].lastName}`,
          username: valueArr[id].username,
          email: valueArr[id].email,
          accounts: valueArr[id].accounts,
          role: valueArr[id].role,
          id,
          key: id,
          phone: valueArr[id].phone,
        });
      });
  }
  return arr;
}

export function createKeyIndex(table) {
  const newKeyIndex = Object.keys(table)
    .map(key => {
      return parseInt(key.slice(1));
    })
    .reduce((a, b) => {
      return a > b ? a : b;
    });

  const isKeyExisting = Object.keys(table).map(key => {
    return isEqual(key, `r${newKeyIndex}`);
  });

  if (isKeyExisting.includes(true)) {
    return `r${newKeyIndex + 1}`;
  } else {
    return `r${newKeyIndex}`;
  }
}

export function cleanFormData(form) {
  delete form.formEditHistory;
  delete form.usersEditing;
  delete form._id;
  delete form.updatedAt;
  delete form.saveTimestamp;
  delete form.id;
  delete form.editedBy;
  delete form.createdAt;
  delete form.clinics;

  form.questionSet.map(singleQuestion => {
    delete singleQuestion._id;

    const question = singleQuestion._questionId;

    delete question.createdAt;
    delete question.defaultQues;
    delete question.editedBy;
    delete question.id;
    delete question.updatedAt;
    delete question._id;
    delete question.__v;

    if (
      question.questionType === "shortAnswer" ||
      question.questionType === "longAnswer" ||
      question.questionType === "date" ||
      question.questionType === "time" ||
      question.questionType === "section"
    ) {
      delete question.firstValue;
      delete question.firstLabel;
      delete question.secondValue;
      delete question.secondLabel;

      delete question.columns;
      delete question.rows;

      delete question.table;

      delete question.options;
    }

    if (
      question.questionType === "multipleChoice" ||
      question.questionType === "checkbox" ||
      question.questionType === "dropdown"
    ) {
      delete question.firstValue;
      delete question.firstLabel;
      delete question.secondValue;
      delete question.secondLabel;

      delete question.columns;
      delete question.rows;

      delete question.table;
    }

    if (
      question.questionType === "multipleChoiceGrid" ||
      question.questionType === "checkboxGrid"
    ) {
      delete question.firstValue;
      delete question.firstLabel;
      delete question.secondValue;
      delete question.secondLabel;

      delete question.table;

      delete question.options;
    }

    if (question.questionType === "table") {
      delete question.firstValue;
      delete question.firstLabel;
      delete question.secondValue;
      delete question.secondLabel;

      delete question.columns;
      delete question.rows;

      delete question.options;

      question.table.table !== undefined &&
        !isEqual({}, question.table.table) &&
        Object.keys(question.table.table).map(tableKey => {
          return question.table.table[tableKey].map(tableCell => {
            if (tableCell.automatedTblCell === "")
              delete tableCell.automatedTblCell;
            if (tableCell.automatedValue === "")
              delete tableCell.automatedValue;
            if (tableCell.disableOnAutomation === "")
              delete tableCell.disableOnAutomation;
            if (tableCell.questionType !== "dropdown")
              delete tableCell.dropdownOptions;
            if (tableCell.ifAnswer === "") delete tableCell.ifAnswer;
            if (tableCell.others === "") delete tableCell.others;
            if (tableCell.questionId === "") delete tableCell.questionId;
            if (tableCell.questionType !== "radio")
              delete tableCell.radioOptions;
            if (tableCell.text === "") delete tableCell.text;
            if (tableCell.questionType !== "Short Answer")
              delete tableCell.placeholder;
            if (tableCell.questionType !== "text") {
              delete tableCell.text;
              delete tableCell.dependent;
            }
            if (tableCell.questionType !== "Short Answer")
              delete tableCell.inputType;
            if (tableCell.questionType !== "disabled")
              delete tableCell.dependent;

            return tableCell;
          });
        });
    }

    return singleQuestion;
  });

  return form;
}

export function getFirstLetters(title) {
  const firstLetters = title
    .split(" ")
    .map(singleWord => singleWord.charAt(0).toUpperCase())
    .join("");

  if (firstLetters.length > 3) {
    return `${firstLetters.slice(0, 2)}...`;
  }

  return firstLetters;
}

export function generateCSV({ patients = [], questions = []}, clinicList) {
  //This function assumes that all of the items are required and there exists in the given data.
  //Make an array of the key of the expected returned values { [object key]: [title of the question] }

  const valuesToGet = {
    lastName: "Last Name",
    firstName: "First Name",
    middleName: "Middle Name",
    dob: "DOB",
  };

  //Map the patients
  let patientInfoList = patients.map(patient => {
    let patientInfo = {};

    //STEP 1: Get questionIds (of valuesToGet arrays)
    let questionIds = Object.keys(valuesToGet).map(info => {
      const selectedQuestion = questions.find(question => {
        const isForThisPatient = patient.patientQuestionSet.some(
          x => x.questionId === question.questionId
        );

        let section = parseInt(question.section);

        if (question.title === "Insurance Information Category") {
          section = 1; //Manual check for Insurance Information Category to have the right section.
        }

        return (
          question.title === valuesToGet[info] &&
          question.defaultQues &&
          section === 1 &&
          isForThisPatient
        );
      });

      return selectedQuestion === undefined
        ? false
        : {
            name: info,
            id: selectedQuestion.questionId,
          };
    });

    questionIds = questionIds.filter(x => x !== false);

    //Step 2: Get Answers
    questionIds.map((question, index) => {
      const { answers } = patient.patientQuestionSet.find(x => x.questionId === question.id);
      let answerValue = '';

      if (answers.length > 0) {
        answerValue = answers[answers.length - 1].answer;
      }

      const clinic = clinicList.find(x => x.clinicId === patient.clinicId);

      if (questionIds.length - 1 === index) {
        return (patientInfo = {
          ...patientInfo,
          [question.name]: answerValue,
          clinicName: clinic !== undefined ? clinic.clinicName : "",
        });
      }

      return (patientInfo = {
        ...patientInfo,
        [question.name]: answerValue,
      });
    });

    return {
      ...patientInfo,
      patientId: patient.patientId,
    };
  });

  return patientInfoList;
}

export function extractFiles({ columns, data }) {
  const extractedData = [];
  
  data
    .map((item, index) => {
      const singleRow = {};

      Object.keys(item)
        .map(itemKey => {
          columns
            .map(column => {
              if (column === itemKey) {
                singleRow[column] = item[itemKey];
              }

              return false;
            });

          return false;
        });

      if (Object.keys(singleRow).length !== 0){
        return extractedData.push(singleRow);
      }

      return false;
    });

  return extractedData;
}

export function getNetworkType(value) {
  let result;

  switch (value) {
    case IN_NETWORK:
      result = IN_NETWORK_ABBR;
      break;
    case OUT_NETWORK:
      result = OUT_NETWORK_ABBR;
      break;
    default:
      result = 'N/A'
      break;
  }
  
  return result;
}

export function addTableNoteIdentifier(questionSets) {

  const withTableNoteIdentifier = questionSets
    .map(singleQuestion => {
      if (singleQuestion.questionType === 'table' && singleQuestion.tableNote !== undefined) {

        Object.keys(singleQuestion.tableNote)
          .map(tableNoteKey => {
            
            Object.keys(singleQuestion.answer)
              .map(tableAnswerKey => { 

                if (singleQuestion.answer[tableAnswerKey][0] === tableNoteKey) {
                  return singleQuestion.answer[tableAnswerKey][0] = `*${singleQuestion.answer[tableAnswerKey][0]}`;
                }

                return false;
              });
            
            return false;
          });
      }

      return singleQuestion;
    });

  return withTableNoteIdentifier;
}

export function hasHighlight(highlightOject) {

  if (highlightOject!== undefined && Object.keys(highlightOject).length !== 0) {

    Object.keys(highlightOject)
      .map(highlightKey => {

        if (highlightOject[highlightKey].includes(true) === true) {
          return true;
        }

        return false;
      });

  }

  return false;
}

export function isEffectiveTerminationDateValid(date) {
  if (date && moment(date).isValid()) {
    const momentDate = moment(date);
    const dateTz = momentDate.utc();
    
    return `${dateTz.format('LL')}`
  } else {
    return null;
  }
}

export function generateShortId() {
  return uuidv4().split('-')[0];
}

export function getFilters(questionId, filters) {

  const filteredQs = filters.find(
    singleFilteredQs => singleFilteredQs.questionId === questionId
  );

  if (filteredQs) {
    return {
      selectedRows: filteredQs.selectedRows,
      selectedColumns: filteredQs.selectedColumns
    };
  }

  return null;
};