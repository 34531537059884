import { all, takeEvery, put, call } from "redux-saga/effects";
import { notification } from "antd";
import {history} from '../../../index'
import * as firebase from "../../firebase";
import actions from "./actions";

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    fetchUsers: firebase.fetchUsers,
  },
};

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  const success = yield call(
    mapAuthProviders.firebase.login,
    email,
    password
  );
  const response = yield call(mapAuthProviders["firebase"].fetchUsers);

  if (success) {
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT",
    });
    yield all(
      response.map((resp) => {
        return put({
          type: 'user/FETCH_USERS',
          payload: [resp],
        })
      }),
    )

    yield history.push("/");

    notification.success({
      message: "Logged In",
      description: "You have successfully logged in!",
    });
  }
  if (!success) {
    yield put({
      type: "user/SET_STATE",
      payload: {
        loading: false,
      },
    });
  }
}

export function* REGISTER({ payload }) {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  // const { authProvider } = yield select((state) => state.settings);
  const result = yield call(
    mapAuthProviders.firebase.register,
    { ...payload }
  );

  if (result.success) {
    yield history.push("/auth/login");
    yield call(mapAuthProviders.firebase.logout);

    notification.success({
      message: "Succesful Registered",
      description: "You have successfully registered!",
    });
  } else {
    notification.error({
      message: result.message.code,
      description: result.message.message
    })
  }

}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true,
    },
  });
  // const { authProvider } = yield select((state) => state.settings);
  const response = yield call(mapAuthProviders.firebase.currentAccount);
  if (response) {
    const { id, email, fullname, firstName, lastName, phone, role } = response;
    yield put({
      type: "user/SET_STATE",
      payload: {
        id,
        fullname,
        firstName,
        lastName,
        email,
        phoneNumber: phone,
        // avatar,
        role,
        authorized: true,
      },
    });
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false,
    },
  });
}

export function* FETCH_USERS() {
  const response = yield call(mapAuthProviders["firebase"].fetchUsers)

  if (response) {
    yield all(
      response.map((resp) => {
        return put({
          type: 'user/FETCH_USERS',
          payload: [resp],
        })
      }),
    )
  }
}

export function* LOGOUT() {
  // const { authProvider } = yield select((state) => state.settings);
  yield call(mapAuthProviders.firebase.logout);
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false,
    },
  });
  // yield put(push('/auth/login'))
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
    FETCH_USERS()
  ]);
}
