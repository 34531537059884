/* eslint-disable array-callback-return */
/* eslint-disable no-cond-assign */
import actions from "./actions";
import moment from "moment";
import { isObject } from "lodash";

const initialState = {
  forms: [],
  editHistory: [],
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    // case actions.ALL_FORMS:{
    //     return Object.assign({}, state, { forms: action.payload });
    // }
    case actions.DISPLAY_SINGLE_FORM: {
      const formsCopy = state.forms;

      const newQuestionSet = [];

      action.payload.questionSet.map(item => {
        newQuestionSet.push({ ...item._questionId, order: item.order });      
      });

      const formExists = formsCopy.find(item => {
        return action.payload.formId === item.formId;
      });

      if (formExists) {
        formsCopy
          .filter(item => {
            return action.payload.formId === item.formId;
          })
          .map(item => {
            return (item.questionSet = newQuestionSet);
          });
      } else {
        formsCopy.push({
          ...action.payload,
          questionSet: newQuestionSet,
        });
      }

      return Object.assign({}, state, { forms: formsCopy });
    }
    case actions.CREATE_NEW_FORM: {
      const { editedBy, formId, id, editTimestamp } = action.payload;

      const formsCopy = state.forms;
      const added = formsCopy.concat(action.payload);

      const editHistoryCopy = [...state.editHistory];

      editHistoryCopy.push({
        action: "form created",
        id,
        formId,
        editedBy,
        timestamp: editTimestamp,
      });

      return Object.assign({}, state, {
        forms: added,
        editHistory: editHistoryCopy,
      });
    }
    case actions.CHANGE_FORM_NAME: {
      const { formId, title } = action.payload;

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.title = title;
          item.editTimestamp = moment().format();

          return item;
        });

      return Object.assign({}, state, { forms: state.forms });
    }
    case actions.CHANGE_FORM_DESCRIPTION: {
      const { id, formId, description, editedBy, timestamp } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.description = description;
          item.editTimestamp = moment().format();

          return item;
        });

      const hasDuplicate = editHistoryCopy.find(singleHistory => {
        return singleHistory.id === id;
      });

      if (hasDuplicate) {
        editHistoryCopy
          .filter(singleHistory => {
            return singleHistory.id === id;
          })
          .map(singleHistory => {
            singleHistory.description = description;
            singleHistory.timestamp = timestamp;
          });
      } else {
        editHistoryCopy.push({
          id,
          formId,
          description,
          editedBy,
          timestamp,
          action: "edited form description",
        });
      }

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.REMOVE_QUESTION: {
      const { id, formId, section, questionId, editedBy, timestamp } =
        action.payload;

      const editHistoryCopy = [...state.editHistory];

      const filtered = state.forms.filter(item => {
        return item.formId === formId;
      });

      filtered[0].questionSet.sort((a, b) => {
        return a.order - b.order;
      });

      const newQuestions = filtered[0].questionSet
        .filter(item => {
          return item.questionId !== questionId;
        })
        .map((item, index) => {
          return { ...item, order: index };
        });

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();
          item.questionSet = newQuestions;
          return item;
        });

      editHistoryCopy.push({
        id,
        formId,
        section,
        questionId,
        editedBy,
        timestamp,
        action: "removed a question",
      });

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.UPDATE_QUESTION_SET: {
      const { id, formId, newQuestions, section, editedBy, timestamp } =
        action.payload;

      const editHistoryCopy = [...state.editHistory];

      // const filtered = state.forms.filter((item) => {
      //   return item.formId === formId;
      // });

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();
          item.questionSet = newQuestions;
          return item;
        });

      editHistoryCopy.push({
        id,
        formId,
        section,
        editedBy,
        timestamp,
        action: "Updated question set",
      });

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.ADD_QUESTION: {
      const {
        _id,
        id,
        formId,
        section,
        questionId,
        title,
        questionType,
        required,
        description,
        options,
        defaultQues,
        table,
        tableOrder,
        editedBy,
        timestamp,
        order,
        show,
        rows,
        columns,
      } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      const added = state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();
          const questionDetails = {
            _id,
            id,
            defaultQues,
            questionId,
            title,
            questionType,
            required,
            section,
            description,
            options,
            table,
            tableOrder,
            order,
            show,
            rows,
            columns,
            // options !== undefined ? options : null,
          };
          
          if (!order) {
            item.questionSet.push(questionDetails);
          } else {
            item.questionSet.splice(order, 0, questionDetails);
          }
          
          const reorderQuestion = item.questionSet.map((item, index) => {
            return { ...item, order: index };
          });

          return { ...item, questionSet: reorderQuestion };
        });

      editHistoryCopy.push({
        id,
        formId,
        questionId,
        questionType,
        editedBy,
        timestamp,
        action: "added a question",
      });

      return Object.assign({}, state, {
        forms: state.forms
          .filter(item => item.formId !== formId)
          .concat(...added),
        editHistory: editHistoryCopy,
      });
    }
    case actions.EDIT_TITLE: {
      const { id, formId, section, questionId, title, editedBy, timestamp } =
        action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .map(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          return (filtered[0].title = title);
        });

      const hasDuplicate = editHistoryCopy.find(singleHistory => {
        return singleHistory.id === id;
      });

      if (hasDuplicate) {
        editHistoryCopy
          .filter(singleHistory => {
            return singleHistory.id === id;
          })
          .map(singleHistory => {
            singleHistory.title = title;
            singleHistory.timestamp = timestamp;
          });
      } else {
        editHistoryCopy.push({
          id,
          formId,
          section,
          questionId,
          title,
          editedBy,
          timestamp,
          action: "edited question title",
        });
      }

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.EDIT_QUESTION_TYPE: {
      const {
        id,
        formId,
        questionId,
        questionType,
        options,
        rows,
        columns,
        firstValue,
        firstLabel,
        secondValue,
        secondLabel,
        editedBy,
        timestamp,
      } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .map(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          if (action.payload.options !== undefined) {
            if (action.payload.questionType === "multipleChoice") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;

              // return Object.assign(filtered[0], ...options);
              filtered[0].options = options;
            } else if (action.payload.questionType === "dropdown") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;

              // return Object.assign(filtered[0], ...options);
              filtered[0].options = options;
            } else if (action.payload.questionType === "checkbox") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;

              // return Object.assign(filtered[0], ...options);
              filtered[0].options = options;
            }
          } else {
            if (action.payload.questionType === "multipleChoice") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;
            } else if (action.payload.questionType === "dropdown") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;
            } else if (action.payload.questionType === "checkbox") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;
            }
          }

          if (action.payload.rows !== undefined) {
            if (action.payload.questionType === "checkboxGrid") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              // delete filtered[0].options;

              delete filtered[0].table;

              // return Object.assign(filtered[0], ...rows, ...columns);
              filtered[0].rows = rows;
              filtered[0].columns = columns;
            } else if (action.payload.questionType === "multipleChoiceGrid") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              // delete filtered[0].options;

              delete filtered[0].table;

              // return Object.assign(filtered[0], ...rows, ...columns);
              filtered[0].rows = rows;
              filtered[0].columns = columns;
            }
          } else {
            if (action.payload.questionType === "checkboxGrid") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              // delete filtered[0].options;

              delete filtered[0].table;
            } else if (action.payload.questionType === "multipleChoiceGrid") {
              filtered[0].questionType = questionType;

              delete filtered[0].firstValue;
              delete filtered[0].firstLabel;
              delete filtered[0].secondValue;
              delete filtered[0].secondLabel;

              // delete filtered[0].options;

              delete filtered[0].table;
            }
          }

          if (action.payload.firstValue !== undefined) {
            if (action.payload.questionType === "linear") {
              filtered[0].questionType = questionType;

              // delete filtered[0].options;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;

              // return Object.assign(filtered[0], {firstValue}, {firstLabel}, {secondValue}, {secondLabel});
              filtered[0].firstValue = firstValue;
              filtered[0].firstLabel = firstLabel;
              filtered[0].secondValue = secondValue;
              filtered[0].secondLabel = secondLabel;
            }
          } else {
            if (action.payload.questionType === "linear") {
              filtered[0].questionType = questionType;

              // delete filtered[0].options;

              delete filtered[0].columns;
              delete filtered[0].rows;

              delete filtered[0].table;
            }
          }

          if (action.payload.table !== undefined) {
            filtered[0].questionType = questionType;

            delete filtered[0].firstValue;
            delete filtered[0].firstLabel;
            delete filtered[0].secondValue;
            delete filtered[0].secondLabel;

            // delete filtered[0].options;

            delete filtered[0].columns;
            delete filtered[0].rows;
            return (filtered[0].table = {});
          }

          if (
            action.payload.questionType === "shortAnswer" ||
            action.payload.questionType === "longAnswer" ||
            action.payload.questionType === "date" ||
            action.payload.questionType === "time"
          ) {
            filtered[0].questionType = questionType;

            delete filtered[0].firstValue;
            delete filtered[0].firstLabel;
            delete filtered[0].secondValue;
            delete filtered[0].secondLabel;

            // delete filtered[0].options;

            delete filtered[0].columns;
            delete filtered[0].rows;

            delete filtered[0].table;
          }

          filtered[0].questionType = questionType;
          return filtered;
        });

      const hasDuplicate = editHistoryCopy.find(singleHistory => {
        return singleHistory.id === id;
      });

      if (hasDuplicate) {
        editHistoryCopy
          .filter(singleHistory => {
            return singleHistory.id === id;
          })
          .map(singleHistory => {
            singleHistory.timestamp = timestamp;
            singleHistory.questionType = questionType;
          });
      } else {
        editHistoryCopy.push({
          id,
          formId,
          questionId,
          questionType,
          editedBy,
          timestamp,
          action: "edited the question type",
        });
      }

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.EDIT_FORM: {
      const { id, formId, questionId, editedBy, timestamp } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .filter(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          if (action.payload.shortAnswerType !== undefined) {
            filtered[0].shortAnswerType = action.payload.shortAnswerType;
          }

          if (action.payload.options !== undefined) {
            filtered[0].options = action.payload.options;

            const hasDuplicate = editHistoryCopy.find(singleHistory => {
              return singleHistory.id === id;
            });

            if (hasDuplicate) {
              editHistoryCopy
                .filter(singleHistory => {
                  return singleHistory.id === id;
                })
                .map(singleHistory => {
                  singleHistory.timestamp = timestamp;
                  singleHistory.options = action.payload.options;
                });
            } else {
              editHistoryCopy.push({
                id,
                formId,
                questionId,
                options: action.payload.options,
                editedBy,
                timestamp,
                action: `added options`,
              });
            }
          }

          if (action.payload.firstValue !== undefined) {
            filtered[0].firstValue = action.payload.firstValue;
            filtered[0].firstLabel = action.payload.firstLabel;
            filtered[0].secondValue = action.payload.secondValue;
            filtered[0].secondLabel = action.payload.secondLabel;

            const hasDuplicate = editHistoryCopy.find(singleHistory => {
              return singleHistory.id === id;
            });

            if (hasDuplicate) {
              editHistoryCopy
                .filter(singleHistory => {
                  return singleHistory.id === id;
                })
                .map(singleHistory => {
                  singleHistory.timestamp = timestamp;
                  singleHistory.firstValue = action.payload.firstValue;
                  singleHistory.firstLabel = action.payload.firstLabel;
                  singleHistory.secondValue = action.payload.secondValue;
                  singleHistory.secondLabel = action.payload.secondLabel;
                });
            } else {
              editHistoryCopy.push({
                id,
                formId,
                questionId,
                firstValue: action.payload.firstValue,
                firstLabel: action.payload.firstLabel,
                secondValue: action.payload.secondValue,
                secondLabel: action.payload.secondLabel,
                editedBy,
                timestamp,
                action: `modified linear scale`,
              });
            }
          }

          if (
            action.payload.rows !== undefined ||
            action.payload.columns !== undefined
          ) {
            filtered[0].rows = action.payload.rows;
            filtered[0].columns = action.payload.columns;

            const hasDuplicate = editHistoryCopy.find(singleHistory => {
              return singleHistory.id === id;
            });

            if (hasDuplicate) {
              editHistoryCopy
                .filter(singleHistory => {
                  return singleHistory.id === id;
                })
                .map(singleHistory => {
                  singleHistory.timestamp = timestamp;
                  singleHistory.rows = action.payload.rows;
                  singleHistory.columns = action.payload.columns;
                });
            } else {
              editHistoryCopy.push({
                id,
                formId,
                questionId,
                rows: action.payload.rows,
                columns: action.payload.columns,
                editedBy,
                timestamp,
                action: `modified the rows and columns`,
              });
            }
          }

          if (action.payload.required !== undefined) {
            filtered[0].required = action.payload.required;

            const hasDuplicate = editHistoryCopy.find(singleHistory => {
              return singleHistory.id === id;
            });

            if (hasDuplicate) {
              editHistoryCopy
                .filter(singleHistory => {
                  return singleHistory.id === id;
                })
                .map(singleHistory => {
                  singleHistory.timestamp = timestamp;
                  singleHistory.required = action.payload.required;
                });
            } else {
              editHistoryCopy.push({
                id,
                formId,
                questionId,
                editedBy,
                timestamp,
                action: `required set to ${action.payload.required}`,
              });
            }
          }

          if (action.payload.quesDesc !== undefined) {
            filtered[0].description = action.payload.quesDesc;

            const hasDuplicate = editHistoryCopy.find(singleHistory => {
              return singleHistory.id === id;
            });

            if (hasDuplicate) {
              editHistoryCopy
                .filter(singleHistory => {
                  return singleHistory.id === id;
                })
                .map(singleHistory => {
                  singleHistory.timestamp = timestamp;
                  singleHistory.quesDesc = action.payload.quesDesc;
                });
            } else {
              editHistoryCopy.push({
                id,
                formId,
                questionId,
                quesDesc: action.payload.quesDesc,
                editedBy,
                timestamp,
                action: `question description set to ${action.payload.quesDesc}`,
              });
            }
          }

          if (action.payload.table !== undefined) {
            filtered[0].table = action.payload.table;
            filtered[0].tableId = action.payload.tableId;

            if (
              action.payload.tableOrder !== undefined &&
              action.payload.tableOrder !== null
            ) {
              filtered[0].tableOrder = action.payload.tableOrder;
            }
          }

          return item;
        });

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.DUPLICATE_QUESTION: {
      const {
        id,
        formId,
        section,
        questionId,
        title,
        questionType,
        required,
        defaultQues,
        options,
        rows,
        columns,
        description,
        editedBy,
        timestamp,
        order,
        show,
        table,
        tableOrder,
        tableId,
      } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      const added = state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          let questionDetails = {
            id,
            questionId,
            title,
            questionType,
            required,
            defaultQues,
            section,
            description,
            show,
            order,
          }

          if (
            action.payload.options !== undefined &&
            action.payload.options.length > 0
          ) {
            questionDetails = {
              ...questionDetails,
              options
            }
          } else if (
            action.payload.rows !== undefined &&
            action.payload.rows.length > 0
          ) {
            questionDetails = {
              ...questionDetails,
              rows,
              columns
            }
          } else if (
            action.payload.firstValue !== undefined &&
            action.payload.firstValue !== ""
          ) {
            questionDetails = {
              ...questionDetails,
              firstValue: action.payload.firstValue,
              secondValue: action.payload.secondValue,
              firstLabel: action.payload.firstLabel,
              secondLabel: action.payload.secondLabel
            }
          } else if (action.payload.description !== "") {
            questionDetails = {
              ...questionDetails,
              description
            }
          } else if (
            action.payload.table !== undefined &&
            Object.keys(action.payload.table).length > 0
          ) {
            questionDetails = {
              ...questionDetails,
              table,
              tableOrder,
              tableId,
            }
          }

          if (!order) {
            item.questionSet.push(questionDetails);
          } else {
            item.questionSet = item.questionSet.map(item => {
              let newOrder = item.order;
              if (order === item.order) {
                newOrder = newOrder + 1;
              }

              return { ...item, order: newOrder };
            });

            item.questionSet.splice(order, 0, questionDetails);
          }

          return item;
        });

      editHistoryCopy.push({
        id,
        formId,
        questionId,
        questionType,
        editedBy,
        timestamp,
        action: "added a question",
      });
      
      return Object.assign({}, state, {
        forms: state.forms
          .filter(item => item.formId !== formId)
          .concat(...added),
        editHistory: editHistoryCopy,
      });

      // return state
    }
    case actions.DELETE_FORM: {
      const { id, formId, editedBy, timestamp } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      const duplicateItem = state.forms.find(item => {
        if (item.formId === formId) {
          return formId;
        }
      });


      editHistoryCopy.push({
        id,
        formId,
        editedBy,
        timestamp,
        action: "deleted the form",
      });

      return Object.assign({}, state, {
        forms: state.forms.filter(item => item.formId !== duplicateItem.formId),
        editHistory: editHistoryCopy,
      });
    }
    case actions.SHOW_DESCRIPTION: {
      const { id, formId, questionId, editedBy, timestamp } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .filter(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          if (filtered[0].show === false) {
            filtered[0].show = true;

            editHistoryCopy.push({
              id,
              formId,
              questionId,
              descriptionShowStatus: true,
              editedBy,
              timestamp,
              action: "toggled on the description",
            });
          } else {
            filtered[0].show = false;

            editHistoryCopy.push({
              id,
              formId,
              questionId,
              descriptionShowStatus: false,
              editedBy,
              timestamp,
              action: "toggled off the description",
            });
          }

          return item;
        });

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.UPDATE_CLINIC_ASSIGNMENT: {
      const { formId, assignedClinics } = action.payload;

      const updatedForm = state.forms.map(form => {
        if (form.formId === formId) {
          form.clinics = assignedClinics;
        }

        return form;
      });

      return Object.assign({}, state, { forms: updatedForm });
    }
    // REDUCERS FOR SECTIONS
    case actions.ADD_SECTION: {
      const {
        id,
        formId,
        section,
        questionType,
        questionId,
        description,
        title,
        defaultSection,
        editedBy,
        timestamp,
        order,
      } = action.payload;

      const formsCopy = [...state.forms];
      const editHistoryCopy = [...state.editHistory];

      // eslint-disable-next-line no-unused-vars
      formsCopy
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();
          const questionDetails = {
            id,
            questionId,
            questionType,
            section,
            title,
            description,
            defaultSection,
            order,
          };

          if (!order) {
            item.questionSet.push(questionDetails);
          } else {
            item.questionSet.splice(order, 0, questionDetails);
          }

          return item;
        });

      editHistoryCopy.push({
        id,
        formId,
        questionId,
        questionType,
        editedBy,
        timestamp,
        action: "added a section",
      });

      return Object.assign({}, state, {
        forms: formsCopy,
        editHistory: editHistoryCopy,
      });
    }
    case actions.EDIT_SECTION_TITLE: {
      const {
        id,
        formId,
        questionId,
        title,
        editedBy,
        timestamp,
      } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .map(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          return (filtered[0].title = title);
        });

      const hasDuplicate = editHistoryCopy.find(singleHistory => {
        return singleHistory.id === id;
      });

      if (hasDuplicate) {
        editHistoryCopy
          .filter(singleHistory => {
            return singleHistory.id === id;
          })
          .map(singleHistory => {
            singleHistory.title = title;
            singleHistory.timestamp = timestamp;
          });
      } else {
        editHistoryCopy.push({
          id,
          formId,
          title,
          editedBy,
          timestamp,
          action: "edited section title",
        });
      }

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.EDIT_SECTION_DESC: {
      const {
        id,
        formId,
        questionId,
        description,
        editedBy,
        timestamp,
      } = action.payload;

      const editHistoryCopy = [...state.editHistory];

      state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          return item.questionSet;
        })
        .map(item => {
          const filtered = item.filter(x => {
            return x.questionId === questionId;
          });

          return (filtered[0].description = description);
        });

      const hasDuplicate = editHistoryCopy.find(singleHistory => {
        return singleHistory.id === id;
      });

      if (hasDuplicate) {
        editHistoryCopy
          .filter(singleHistory => {
            return singleHistory.id === id;
          })
          .map(singleHistory => {
            singleHistory.description = description;
            singleHistory.timestamp = timestamp;
          });
      } else {
        editHistoryCopy.push({
          id,
          formId,
          description,
          editedBy,
          timestamp,
          action: "edited section description",
        });
      }

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.DELETE_SECTION: {
      const { id, values, primaryDetails, editedBy, timestamp } =
        action.payload;

      const editHistoryCopy = [...state.editHistory];

      const filtered = state.forms.filter(item => {
        return item.formId === primaryDetails.formId;
      });

      const sectionToDelete = filtered[0].questionSet.find(item => {
        return item.questionId === values.questionId;
      });

      const qsUpdatedOrder = filtered[0].questionSet
        .filter(item => {
          return item.questionId !== values.questionId;
        })
        .map((item, index) => {
          return { ...item, order: index };
        });

      let newQuestions = [];

      if (isObject(qsUpdatedOrder)) {
        const qsUpdatedSection = qsUpdatedOrder
          .filter(item => {
            return parseInt(item.section) >= parseInt(sectionToDelete.section);
          })
          .map(item => {
            item.section = `${parseInt(item.section) - 1}`;

            return item;
          });

        newQuestions = [
          ...qsUpdatedOrder.filter(item => {
            return parseInt(item.section) < parseInt(sectionToDelete.section);
          }),
          ...qsUpdatedSection,
        ].reduce((unique, o) => {
          if (!unique.some(obj => obj.questionId === o.questionId)) {
            unique.push(o);
          }

          return unique;
        }, []);
      }

      state.forms
        .filter(item => {
          return item.formId === primaryDetails.formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();
          item.questionSet = newQuestions;
          return item;
        });

      editHistoryCopy.push({
        id,
        formId: primaryDetails.formId,
        questionId: values.questionId,
        questionType: values.questionType,
        editedBy,
        timestamp,
        action: "deleted a section",
      });

      return Object.assign({}, state, {
        forms: state.forms,
        editHistory: editHistoryCopy,
      });
    }
    case actions.DUPLICATE_SECTION: {
      const {
        formId,
        id,
        title,
        questionType,
        section,
        description,
        order,
        questionId,
        defaultSection,
      } = action.payload;

      const added = state.forms
        .filter(item => {
          return item.formId === formId;
        })
        .map(item => {
          item.editTimestamp = moment().format();

          item.questionSet.push({
            id,
            questionId,
            title: title,
            questionType: questionType,
            section: section,
            description: description,
            order,
            defaultSection,
          });

          return item;
        });

      return Object.assign({}, state, {
        forms: state.forms
          .filter(item => item.formId !== formId)
          .concat(...added),
      });
    }
    case actions.SAVE_TIMESTAMP: {
      state.forms.find(form => {
        if (form.formId === action.payload.formId) {
          form.saveTimestamp = moment().format();
        }
      });

      return Object.assign({}, state, { forms: state.forms });
    }
    default:
      return state;
  }
}
