/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
// import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { notification } from "antd";
import UsersApi from "../api/User";
import CookieApi from "../api/Cookie";

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

export const firebaseAuth = getAuth(firebaseApp);
export const firebaseDatabase = getDatabase(firebaseApp);

export async function login(email, password) {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(async (userCredential) => {
      return userCredential.user.getIdToken(true).then(async (idToken) => {
        return CookieApi.getHttpOnlyCookie({ idToken }).then(() => {
          return {
            cookie: idToken,
            role: userCredential.user.role,
          };
        });
      });
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function register(payload) {
  const registeredUser = await UsersApi.SelfRegistration(payload);
  return registeredUser;
}

export async function fetchUsers() {
  function snapshotToArray(snapshot) {
    const user = firebaseAuth.currentUser;
    const arr = [];

    if (user) {
      CookieApi.getHttpOnlyCookie({ idToken: user.accessToken });

      const valueArr = { ...snapshot.val() };

      Object.keys(valueArr)
        .filter((id) => {
          return user.uid !== id;
        })
        // .filter((id) => {
        //   return (
        //     valueArr[id]?.authentication?.eligibilityForms?.authorized === true
        //   );
        // })
        .map((id) => {
          return arr.push({
            ...valueArr[id],
            id,
            key: id,
            email: valueArr[id]?.userInfo?.email,
            fullname: valueArr[id]?.fullname || valueArr[id]?.userInfo?.firstName + " " + valueArr[id]?.userInfo?.lastName,
            phone: valueArr[id]?.userInfo?.phone,
            username: valueArr[id]?.userInfo?.username,
          })
        });
    }
    return arr;
  }

  const dbRef = ref(getDatabase());
  const usersArray = get(child(dbRef, "users/"))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshotToArray(snapshot);
      }
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });

  return usersArray;
}

export async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        const getUserData = async () => {
          if (user) {
            const dbRef = ref(getDatabase());
            const userFields = await get(child(dbRef, `users/${user.uid}`))
              .then((snapshot) => {
                if (snapshot.exists()) {
                  return snapshot.val();
                }
              })
              .catch((error) => {
                notification.warning({
                  message: error.code,
                  description: error.message,
                });
              });

            const mergedUser = Object.assign(user, {
              id: user.uid,
              test: "123",
              firstName: userFields.userInfo?.firstName,
              lastName: userFields.userInfo?.lastName,
              fullname:
                userFields.userInfo?.firstName +
                " " +
                userFields.userInfo?.lastName,
              role: userFields.role,
              phone: userFields?.userInfo?.phone,
              // avatar: user.photoUrl,
            });

            return mergedUser;
          }

          return user;
        };
        resolve(getUserData());
      }, reject);
    });
  }
  return getCurrentUser(firebaseAuth);
}

export async function logout() {
  return firebaseAuth.signOut().then(() => {
    CookieApi.deleteHttpOnlyCookie();
  });
}
