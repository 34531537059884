import { errorMessage } from "../../utils";
import axiosInstance from "./axios-instance-api";

/////
///// @Main
/////

async function GetTable(tableId, headerParams = false) {
  try {
    let result = await axiosInstance().get(
      `/table/get/${tableId}`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetTable", error);
  }
}

async function CreateTable(questionId, parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().post(
      `/table/create/${questionId}`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("CreateTable", error);
  }
}

async function UpdateTable(tableId, parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/table/update/${tableId}`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("UpdateTable", error);
  }
}

async function DeleteTable(formId, parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/table/delete/${formId}`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DeleteTable", error);
  }
}

async function DuplicateTable(
  questionId,
  parameters = {},
  headerParams = false
) {
  try {
    let result = await axiosInstance().post(
      `/forms/table/duplicate/${questionId}/`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DuplicateTable", error);
  }
}

async function addOrderToTables(headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/table/add-order`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("addOrderToTables", error);
  }
}

const Table = {
  GetTable,
  CreateTable,
  UpdateTable,
  DeleteTable,
  DuplicateTable,
  addOrderToTables,
};

export default Table;
