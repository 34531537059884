import { errorMessage } from "../../utils";
import axiosInstance from "./axios-instance-api";

async function GetSingleClinic(clinicId, headerParams = false) {
  try {
    let result = await axiosInstance().get(
      `/clinics/get/${clinicId}`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetSingleClinic", error);
  }
}

async function GetClinicList(headerParams = false) {
  try {
    let result = await axiosInstance().get(
      `/clinics/get`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetClinicList", error);
  }
}

async function GetClinic(clinicId, headerParams = false) {
  try {
    let result = await axiosInstance().get(
      `/clinics/get/${clinicId}`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetClinic", error);
  }
}

async function AddClinic(parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().post(
      `/clinics/add`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("AddClinic", error);
  }
}

async function UpdateClinic(clinicId, parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/update/${clinicId}`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("UpdateClinic", error);
  }
}

async function DeleteClinic(clinicId, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/delete/${clinicId}`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DeleteClinic", error);
  }
}

async function SaveFilter(clinicId, parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/filter/save/${clinicId}`,
      parameters
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("SaveFilter", error);
  }
}

async function GetFilter(clinicId, formId, headerParams = false) {
  if (formId === undefined || formId === '') return;
  
  try {
    let result = await axiosInstance().post(
      `/clinics/filter/get`, { clinicId, formId }
    );
    
    if (!result.data.success) {
      throw result.data.message;
    }
    
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetFilter", error);
  }
}

async function GetAllPatientQuestions(clinicId, headerParams = false) {
  try {
    let result = await axiosInstance().get(
      `/clinics/get/patient-form-questions/${clinicId}`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("GetAllPatientQuestions", error);
  }
}

async function CronUpdatePatientDocuments(headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/cron/update/all/patients`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("CronUpdatePatientDocuments", error);
  }
}

async function UpdateAllClinicFilters(headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/update/all/filters`
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("UpdateAllClinicFilters", error);
  }
}

async function UpdateClinicFilterNameDesc(parameters, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/update/filter/name/description`, {
        ...parameters
      }
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("UpdateClinicFilterNameDesc", error);
  }
}

async function DeleteClinicFilter(clinicID, activeKey, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/clinics/filter/delete`, {
        clinicId: clinicID,
        _id: activeKey
      }
    );
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DeleteClinicFilter", error);
  }
}

async function DeletePatientObjId(headerParams = false) {
  try {
    let result = await axiosInstance().patch(`/clinics/patients/objId/delete`);
    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DeletePatientObjId", error);
  }
}

async function UploadExcelBatchFile(parameters = {}, headerParams = false) {
  try {
    let result = await axiosInstance().post(
      `/clinics/patients/batch/upload/xlsx`,
      parameters,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        // 6 minutes
        timeout: 6 * 60 * 1000,
      }
    );

    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("UploadExcelBatchFile", error);
  }
}

async function DownloadExcelTemplate(formId, headerParams = {}) {
  try {
    let result = await axiosInstance().post(
      `/clinics/xlsx/generate?formId`,
      { formId }, headerParams
    );

    if (result.data?.success === false) {
      throw result.data.message;
    }

    return result.data;
  } catch (error) {
    return errorMessage("DownloadExcelTemplate", error);
  }
}

async function DownloadSpreadsheetReport(fileName, headerParams = {}) {
  try {
    let result = await axiosInstance().post(
      `/clinics/download/patients/not-saved`,
      { fileName }, headerParams
    );

    return headerParams ? result : result.data;
  } catch (error) {
    return errorMessage("DownloadSpreadsheetReport", error);
  }
}

async function UpdateFilterQuestionOrder(parameters) {
  try {
    const result = await axiosInstance().post(
      `/clinics/specialty-filter/question/order/update`, parameters
    );

    return result.data;
  } catch (error) {
    return errorMessage("UpdateFilterQuestionOrder", error);
  }
}

async function UpdateFilterTableRowOrder(parameters) {
  try {
    const result = await axiosInstance().post(
      `/clinics/specialty-filter/question/table/row/order/update`, parameters
    );

    return result.data;
  } catch (error) {
    return errorMessage("UpdateFilterTableRowOrder", error);
  }
}

async function UpdateFilterTableColumnOrder(parameters) {
  try {
    const result = await axiosInstance().post(
      `/clinics/specialty-filter/question/table/column/order/update`, parameters
    );

    return result.data;
  } catch (error) {
    return errorMessage("UpdateFilterTableColumnOrder", error);
  }
}

const Clinics = {
  GetSingleClinic,
  GetClinicList,
  GetClinic,
  AddClinic,
  UpdateClinic,
  DeleteClinic,
  GetFilter,
  SaveFilter,
  GetAllPatientQuestions,
  CronUpdatePatientDocuments,
  UpdateAllClinicFilters,
  UpdateClinicFilterNameDesc,
  DeleteClinicFilter,
  DeletePatientObjId,
  UploadExcelBatchFile,
  DownloadExcelTemplate,
  DownloadSpreadsheetReport,
  UpdateFilterQuestionOrder,
  UpdateFilterTableRowOrder,
  UpdateFilterTableColumnOrder
};

export default Clinics;
