import axiosInstance from "./axios-instance-api";

const getHttpOnlyCookie = async (data) => {
	return axiosInstance().post(`/cookie`, data, { withCredentials: true });
}

const deleteHttpOnlyCookie = async () => {
    return axiosInstance().delete( `/cookie/delete`, { withCredentials: true })
}

const CookieAPI = {
    getHttpOnlyCookie,
    deleteHttpOnlyCookie
};

export default CookieAPI;