/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getDatabase, ref, onValue } from "firebase/database";
import { Button, Dropdown, notification } from "antd";
import {
  CoffeeOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

const mapStateToProps = ({ user, clinics, insurances, dispatch }) => ({
  dispatch,
  user,
  clinics,
  insurances,
});

const developerIds = ["v1YF4orXH2hP8yuE9pjnpAMHdBm2", "CpxMsC93rZcbcKzC1Yh0d5oQm0D2"];

const Header = ({ user, insurances, dispatch }) => {

  const database = getDatabase();
  const [breakPoint, setBreakPoint] = useState();
  const [isMounted, setIsMounted] = useState(false);
  const [userRole, setUserRole] = useState(user.role);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, [isMounted]);

  useEffect(() => {
    if (isMounted && user.role !== undefined) {
      setUserRole(user.role)
    }
  }, [isMounted, user])

  useEffect(() => {
    const userRef = ref(database, 'users/' + user.id);

    onValue(userRef, (snapshot) => {
      const updatedUserData = snapshot.val();
      
      if (updatedUserData.role !== user.role) {

        notification.warning({
          message: 'Important update',
          description: 'the web application will reload in a few seconds.',
          interval: 10
        });

        const interval = setInterval(() => {
          window.location.reload(true);
        }, 5000);

        return () => {
          clearInterval(interval);
        }
      }
    });
  }, [user, database])
  
  const breakPoints = {
    mobile: "(max-width:600px)",
    tablet: "(min-width:600px) and (max-width:992px)",
    laptop: "(min-width:993px) and (max-width:1023px)",
    desktop: "(min-width:1024px)",
  };

  useEffect(() => {
    breakPointObserver(breakPoints, setBreakPoint);
    return () => {
      setIsMounted(false);
    };
  }, [breakPoint, breakPoints]);

  useEffect(() => {
    if (isMounted) {
      insurances.groupNumbers.map(item => {
        if (item.submitTimestamp === undefined) {
          return dispatch({
            type: "insurance/DELETE_RESPONSE",
            payload: item.groupNumberID,
          });
        }

        return false;
      });
    }
  }, []);

  return (
    <div
      style={
        // breakPoint === "mobile" || breakPoint === "tablet" ?
        //   { display: "grid", gridTemplateColumns: "2fr 8fr 2fr", alignItems: "center", margin: "0 10px 0 10px"}
        // :
        //   { display: "flex", justifyContent: "space-between", alignItems: "center",}
        {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }
      }
    >
      {(breakPoint === "mobile" || breakPoint === "tablet") && <NavBarButton userRole={userRole} />}
      {(breakPoint === "laptop" || breakPoint === "desktop") && <MenuItems userRole={userRole} />}

      {(breakPoint === "mobile" || breakPoint === "tablet") && (
        <AccountSettingsMini user={user} dispatch={dispatch} />
      )}
      {(breakPoint === "laptop" || breakPoint === "desktop") && (
        <AccountSettings user={user} dispatch={dispatch} />
      )}
    </div>
  );
};

const MenuItems = ({userRole}) => {

  const [counter, setCounter] = useState(1);

  const getFontWeight = useCallback((page) => {
    
    if (document.URL.includes(page)) {
      return {
        color: 'black',
        fontSize: 16,
        fontWeight: 600
      }
    }

    return {
      color: 'black',
      fontSize: 16
    }
  }, [counter])

  const incrementCounter = useCallback(() => {
    setCounter(prevState => prevState + 1);
  }, []);

  return (
    <>
      {
        userRole === 'admin' ? (
          <div
            style={{
              display: 'grid',
              gridAutoFlow: 'column'
            }}
          >
            <Button type='link' onClick={incrementCounter}>
              <Link 
                to='/forms'
                className="align-middle" 
                style={getFontWeight('/forms')}
              >
                Forms
              </Link>
            </Button>

            <Button type='link' onClick={incrementCounter}>
              <Link 
                to='/clinics'
                className="align-middle" 
                style={getFontWeight('/clinics')}
              >
                Clinics
              </Link>
            </Button>

            <Button type='link' onClick={incrementCounter}>
              <Link 
                to='/insurance'
                className="align-middle" 
                style={getFontWeight('/insurance')}
              >
                Insurances
              </Link>
            </Button>

            <Button type='link' onClick={incrementCounter}>
              <Link 
                to='/accounts'
                className="align-middle" 
                style={getFontWeight('/accounts')}
              >
                Accounts
              </Link>
            </Button>

            <Button type='link' onClick={incrementCounter}>
              <Link 
                to='/tools'
                className="align-middle" 
                style={getFontWeight('/tools')}
              >
                Tools
              </Link>
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'grid',
              gridAutoFlow: 'column'
            }}
          >
            <Button type='link'>
              <Link 
                to='/clinics'
                className="align-middle" 
                style={getFontWeight('/clinics')}
              >
                Clinics
              </Link>
            </Button>

            <Button type='link'>
              <Link 
                to='/insurance'
                className="align-middle" 
                style={getFontWeight('/insurance')}
              >
                Insurances
              </Link>
            </Button>

            <Button type='link'>
              <Link 
                to='/tools'
                className="align-middle" 
                style={getFontWeight('/tools')}
              >
                Tools
              </Link>
            </Button>
          </div>
        )
      }
    </>
  )
};

const NavBarButton = ({userRole}) => {
  return (
    <div
      className='dropdown'
      style={{
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        width: "20px",
        // height: "20px",
        backgroundColor: "white",
        border: "none",
        padding: 0,
      }}
    >
      <a
        style={{ color: "rgba(0, 0, 0, 0.55)" }}
        role='button'
        id='dropdownMenuLink'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          fill='currentColor'
          className='bi bi-list'
          viewBox='0 0 16 16'
        >
          <path
            fillRule='evenodd'
            d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z'
          />
        </svg>
      </a>

      <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
        {
          userRole === 'admin' && <Link className='dropdown-item' to='/'>
            Forms
          </Link>
        }
        
        <Link className='dropdown-item' to='/clinics'>
          Clinics
        </Link>
        <Link className='dropdown-item' to='/insurance'>
          Insurance
        </Link>

        {
          userRole === 'admin' && (
            <Link className='dropdown-item' to='/accounts'>
              Accounts
            </Link>
          )
        }
        
        <Link className='dropdown-item' to='/tools'>
          Tools
        </Link>
      </div>
    </div>
  );
};

const AccountSettingsMini = ({ user, dispatch }) => {

  const history = useHistory();

  const onClick = () => {
    dispatch({
      type: "user/LOGOUT",
    });

    dispatch({
      type: "user/SET_STATE",
      payload: {
        id: '',
        name: '',
        email: '',
        avatar: '',
        role: '',
        username: '',
        authorized: false,
        users: []
      },
    });
  };

  const dropdownItems = [
    {
      label: (
        <div className="d-flex align-items-center">
          <UserOutlined /> <Link to='/profile' style={{ marginLeft: 10, color: 'inherit' }}>Profile</Link>
        </div>
      ),
      key: "0",
    },
    { type: "divider" },
    {
      label: (
        <div onClick={onClick} className="d-flex align-items-center">
          <LogoutOutlined /> <span style={{ marginLeft: 10, color: 'inherit' }}>Logout</span>
        </div>
      ),
      key: "3",
    }
  ]

  if (developerIds.includes(user.id)) {
    dropdownItems.push({
      label: (
        <div 
          onClick={() => history.push("/developer/settings")} 
          className="d-flex align-items-center"
        >
          <CoffeeOutlined /> <span style={{ color: 'inherit', marginLeft: 10 }}>Developer settings</span>
        </div>
      ),
      key: "4",
    })
  }

  return (
    <div className='row'>
      <div className='col align-self-end' style={{ textAlign: "right" }}>
        <Dropdown 
          trigger={["click"]}
          menu={{ 
            items: dropdownItems
          }}
        >
          <Button size='large' shape='round'>
            <UserOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

const AccountSettings = ({ user, dispatch }) => {

  const history = useHistory();

  const onClick = () => {
    dispatch({
      type: "user/LOGOUT",
    });

    dispatch({
      type: "user/SET_STATE",
      payload: {
        id: '',
        name: '',
        email: '',
        avatar: '',
        role: '',
        username: '',
        authorized: false,
        users: []
      },
    });
  };

  const dropdownItems = [
    {
      label: (
        <div className="d-flex align-items-center">
          <UserOutlined /> <Link to='/profile' style={{ color: 'inherit', marginLeft: 10 }}>Profile</Link>
        </div>
      ),
      key: "0",
    },
    // {
    //   label: (
    //     <div className="d-flex align-items-center">
    //       <SettingOutlined /> <Link to='/settings' style={{ color: 'inherit', marginLeft: 10 }}>Settings</Link>
    //     </div>
    //   ),
    //   key: "1",
    // },
    { type: "divider" },
    {
      label: (
        <div onClick={onClick} className="d-flex align-items-center">
          <LogoutOutlined /> <span style={{ color: 'inherit', marginLeft: 10 }}>Logout</span>
        </div>
      ),
      key: "3",
    },
  ]

  if (developerIds.includes(user.id)) {
    dropdownItems.push({
      label: (
        <div 
          onClick={() => history.push("/developer/settings")} 
          className="d-flex align-items-center"
        >
          <CoffeeOutlined /> <span style={{ color: 'inherit', marginLeft: 10 }}>Developer settings</span>
        </div>
      ),
      key: "4",
    })
  }

  return (
    <div className='row'>
      <div className='col align-self-end' style={{ textAlign: "right" }}>
        <Dropdown 
          trigger={["click"]}
          menu={{
            items: dropdownItems
          }}
        >
          <Button size='large'>
            <span className='align-middle'>{user.fullname}</span>{" "}
            <UserOutlined className='align-middle' />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

function matchMediaQuery(breakPoints, setBreakPoint) {
  for (var key of Object.keys(breakPoints)) {
    if (window.matchMedia(`${breakPoints[key]}`).matches) {
      setBreakPoint(key);
    }
  }
}

function breakPointObserver(breakPoints, setBreakPoint) {
  matchMediaQuery(breakPoints, setBreakPoint);

  window.addEventListener("resize", () => {
    matchMediaQuery(breakPoints, setBreakPoint);
  });
}

export default connect(mapStateToProps)(Header);
