/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import forms from "./forms/reducers";
import clinics from "./clinics/reducers";
import insurances from "./insurances/reducers";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    forms,
    clinics,
    insurances,
  });
