/* eslint-disable no-unused-vars */
import { SiderContext } from "antd/lib/layout/Sider";

const actions = {
  // ALL_FORMS: "form/ALL_FORMS",
  DISPLAY_SINGLE_FORM: "form/DISPLAY_SINGLE_FORM",
  CREATE_NEW_FORM: "form/CREATE_NEW_FORM",
  CHANGE_FORM_NAME: "form/CHANGE_FORM_NAME",
  CHANGE_FORM_DESCRIPTION: "form/CHANGE_FORM_DESCRIPTION",
  REMOVE_QUESTION: "form/REMOVE_QUESTION",
  ADD_QUESTION: "form/ADD_QUESTION",
  EDIT_TITLE: "form/EDIT_TITLE",
  EDIT_QUESTION_TYPE: "form/EDIT_QUESTION_TYPE",
  EDIT_FORM: "form/EDIT_FORM",
  DUPLICATE_QUESTION: "form/DUPLICATE_QUESTION",
  DELETE_FORM: "form/DELETE_FORM",
  SHOW_DESCRIPTION: "form/SHOW_DESCRIPTION",
  UPDATE_QUESTION_SET: "form/UPDATE_QUESTION_SET",
  SAVE_TIMESTAMP: "form/SAVE_TIMESTAMP",
  UPDATE_CLINIC_ASSIGNMENT: "form/UPDATE_CLINIC_ASSIGNMENT",

  // ACTIONS FOR FORM SECTIONS
  ADD_SECTION: "form/ADD_SECTION",
  EDIT_SECTION_TITLE: "form/EDIT_SECTION_TITLE",
  EDIT_SECTION_DESC: "form/EDIT_SECTION_DESC",
  DELETE_SECTION: "form/DELETE_SECTION",
  DUPLICATE_SECTION: "form/DUPLICATE_SECTION",
};
  
  export default actions;