import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Header from './../../Header/index';
import Footer from './../../Footer/index';
import { Layout } from "antd";

const mapStateToProps = ({ settings }) => ({
  settings
});

const MainLayout = ({children}) => {
  const url = window.location.href;
  const form = url.includes("forms")
  const create = url.includes("create-form");
  const edit = url.includes('editformId')
  const preview = url.includes("print-preview");
  const answerform = url.includes("answer-form");
  const InsurancePolicies = url.includes("insurance");
  const patient = url.includes("patient");
  const clinics = url.includes("clinics");
  const insurance = url.includes("insurance");
  const addPatient = url.includes('add-patient-data');
  const editPatient = url.includes('edit-patient-data');
  const viewGroupNo = url.includes('view-group-number');
  const addGroupNo = url.includes('add-group-number');
  const filterForm = url.includes('filterformId');
  const accounts = url.includes('accounts');
 
  return (
    <Layout style={{minHeight: '100vh'}}>
      {create || edit ? null
      :(
        <Layout.Header 
          style={{backgroundColor: '#ffff'}}
        >
          <Header />
        </Layout.Header> 
      )}
      <Layout.Content 
        style={form || create || edit || preview || answerform ? {backgroundColor: "rgb(241, 243, 244)"} : editPatient || addPatient ? {backgroundColor: "#ffff"} : InsurancePolicies || clinics  || insurance ? {}: {backgroundColor: "#ffff"} || filterForm ? {backgroundColor: '#F1F3F4'} : null}
      >
        {children}
      </Layout.Content>
      <Layout.Footer 
        style={form || create || edit || clinics || patient || insurance || viewGroupNo || addGroupNo || preview || answerform || filterForm || accounts ? {backgroundColor: '#ffff'} : null}
      >
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(MainLayout));
