const actions = {
  SET_STATE: "insurance/SET_STATE",
  SAVE_INITIAL_PROGRESS: "insurance/SAVE_INITIAL_PROGRESS",
  SUBMIT_WITH_TIMESTAMP: "insurance/SUBMIT_WITH_TIMESTAMP",
  DELETE_RESPONSE: "insurance/DELETE_RESPONSE",
  SAVE_ANSWER: "insurance/SAVE_ANSWER",
  SAVE_ANSWER_LIST: "insurance/SAVE_ANSWER_LIST",
  FETCH_ANSWER_LIST: "insurance/FETCH_ANSWER_LIST",
  FETCH_NOTES_LIST: "insurance/FETCH_NOTES_LIST",
  UPDATE_INSURANCE_LIST: 'insurance/UPDATE_INSURANCE_LIST',
  ADD_INSURANCE: 'insurance/ADD_INSURANCE',
  ADD_GROUPNO: 'insurance/ADD_GROUPNO',
  ADD_FORMID: 'insurance/ADD_FORMID',
  EDIT_GROUP_NUMBER: 'insurance/EDIT_GROUP_NUMBER',
  ADD_QUESTIONS: 'insurance/ADD_QUESTIONS',
  SAVE_NOTES: 'insurance/SAVE_NOTES',
  FETCH_TABLE_NOTES_LIST: 'insurance/FETCH_TABLE_NOTES_LIST',
  SAVE_TABLE_NOTE: 'insurance/SAVE_TABLE_NOTE',
  SAVE_GROUP_NUMBER_NAME: 'insurance/SAVE_GROUP_NUMBER_NAME',
  DUPLICATE_GROUP_NUMBER: 'insurance/DUPLICATE_GROUP_NUMBER',
};

export default actions;
