const actions = {
  SAVE_CLINIC: "clinic/SAVE_CLINIC",
  UPDATE_CLINIC_LIST: 'clinic/UPDATE_CLINIC_LIST',

  SET_STATE: "patient/SET_STATE",
  SAVE_INITIAL_PROGRESS: "patient/SAVE_INITIAL_PROGRESS",
  SAVE_QUESTION: "patient/SAVE_QUESTION",
  SAVE_ANSWER: "patient/SAVE_ANSWER",
  SUBMIT_WITH_TIMESTAMP: "patient/SUBMIT_WITH_TIMESTAMP",
  DELETE_RESPONSE: "patient/DELETE_RESPONSE",
  SAVE_GROUP_NUMBER: "patient/SAVE_GROUP_NUMBER",
  SAVE_PATIENT_GROUP_NUMBER: "patient/SAVE_PATIENT_GROUP_NUMBER",
  SAVE_NOTES: "patient/SAVE_NOTES",
  SAVE_HIGHLIGHTS: "patient/SAVE_HIGHLIGHTS",
  SAVE_NEW_QUESTION_ANSWER: "patient/SAVE_NEW_QUESTION_ANSWER",
  CHANGE_PATIENT_GROUP_NUMBER: "patient/CHANGE_PATIENT_GROUP_NUMBER",
  FETCH_ANSWER_LIST: "patient/FETCH_ANSWER_LIST",
  FETCH_NOTES_LIST: "patient/FETCH_NOTES_LIST",
  FETCH_HIGHLIGHT_LIST: "patient/FETCH_HIGHLIGHT_LIST",
  ADD_PATIENT: "patient/ADD_PATIENT",
  UPDATE_PATIENT_GN: "patient/UPDATE_PATIENT_GN",
  FETCH_TABLE_NOTES_LIST: "patient/FETCH_TABLE_NOTES_LIST",
  SAVE_TABLE_NOTES: "patient/SAVE_TABLE_NOTES",
};

export default actions;
