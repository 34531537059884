import { Layout, Space } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AppFooter from "../../Footer";
const mapStateToProps = ({user, settings }) => ({
  user
  // logo: settings.logo,
  // isGrayTopbar: settings.isGrayTopbar,
  // isCardShadow: settings.isCardShadow,
  // isSquaredBorders: settings.isSquaredBorders,
  // isBorderless: settings.isBorderless,
  // authPagesColor: settings.authPagesColor,
  // isAuthTopbar: settings.isAuthTopbar,
});

const AuthLayout = ({user, children}) => {
  return (
    <Layout className="layout" style={{minHeight: '100vh'}}>
      <Header style={{backgroundColor: '#ffff'}}>
        <div className="row">
          <div className="col align-self-start">
            Dental Eligibility Forms
          </div>
          <div className="col align-self-end">
          {user.authorized === true ? null : (
            <div style={{float: 'right'}}>
              <Space direction='horizontal'>
                <Link to="/auth/login" className="btn btn-outline-secondary">
                  Log In
                </Link>{" "}
                <Link to="/auth/signup" className="btn btn-primary">
                  Sign Up
                </Link>
              </Space>
            </div>
          )}
          </div>
        </div>
      </Header>
      <Content style={{marginTop: '5%', marginBottom: '5%'}}>
        {children}
      </Content>
      <Footer style={{backgroundColor: '#ffff'}}>
        <AppFooter />
      </Footer>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
