//QuestionTypes
export const SHORT_ANSWER = 'shortAnswer';
export const MULTIPLE_CHOICE = 'multipleChoice';
export const LONG_ANSWER = 'longAnswer';
export const DROPDOWN = 'dropdown';
export const LINEAR = 'linear';
export const CHECKBOX_GRID = 'checkboxGrid';
export const DATE = 'date';
export const TIME = 'time';
export const CHECKBOX = 'checkbox';
export const MULTIPLE_CHOICE_GRID = 'multipleChoiceGrid';
export const SECTION = 'section';
export const CHANGE_GROUP_NUMBER = 'changeGroupNumber';

//Dispatch

//Collection AND Pages
export const FORMS          = "forms";
export const CLINICS        = "clinics";
export const INSURANCES     = "insurances";

export const PATIENT        = "patient";
export const GN_HISTORY     = "gnHistory";
export const ANSWER         = "answer";
export const NOTES          = "notes";
export const GROUP_NUMBER   = "groupNumber";
export const QUESTION       = "questions";
export const EDIT_HISTORY   = "editHistory";
export const TABLE          = "table";

//For Clinic, Form, Insurance and GN Titles
export const ITEM_NAME_MAX = 100;
export const ITEM_FIELD_MAX = 100; //Generic
export const EMAIL_MAX = 60;
export const ADDRESS_MAX = 100;

//MaxLength - Input Components
export const SHORT_ANSWER_MAX = 100;
export const OTHERS_MAX = 100;
export const TABLE_TEXT_MAX = 100;
export const PARAGRAPH_MAX = 100;

//MaxLength - Form Components
export const OPTION_LABEL_MAX = 100;
export const QUESTION_NAME_MAX = 100;
export const QUESTION_DESCRIPTION_MAX = 100;


//Other Key Words
export const OTHER = "Other...";
export const VERIFICATION_COLOR = "#52c41a";