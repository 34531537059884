const actions = {
  SET_STATE: "user/SET_STATE",
  LOGIN: "user/LOGIN",
  REGISTER: "user/REGISTER",
  FETCH_USERS: "user/FETCH_USERS",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "user/LOGOUT",
  ADD_USER: "user/ADD_USER",
  EDIT_USER: "user/EDIT_USER"
};

export default actions;
