import axios from "axios";
import { errorMessage } from "../../utils";
import axiosInstance from "./axios-instance-api";

async function SelfRegistration(parameters, headerParams = false) {
  try {
    let result = await axios.post(
      `${process.env.REACT_APP_DB_PORT}/user/self-registration`, parameters
    );

    return headerParams ? result : result.data;
  } catch (error) {

    errorMessage("SelfRegistration", error);
		return error
  }
}

async function AddUser(parameters, headerParams = false) {
  try {
    let result = await axiosInstance().post(
      `/user/add`, parameters
    );

    if (!result.data.success) {
      throw result.data.message;
    }
    return headerParams ? result : result.data;
  } catch (error) {

    errorMessage("AddUser", error);
		return error
  }
}

async function EditUserInfo(userId, parameters, headerParams = false) {
  try {
    let result = await axiosInstance().patch(
      `/user/edit/${userId}`,
      parameters  
    );
    
    if (!result.data.success) {
      throw result.data.message || result.data.error;
    }
    return headerParams ? result : result.data;
  } catch (error) {

    errorMessage("EditUserInfo", error);
		return error
  }
}

async function ForgotPassword(parameters, headerParams = false) {
  try {
    let result = await axios.post(
      `${process.env.REACT_APP_DB_PORT}/user/forgot-password`,
      parameters
    );
    
    if (result.data) {
      throw result.data;
    }
    return headerParams ? result : result.data;
  } catch (error) {
    errorMessage("ForgotPassword", error);
		return error
  }
}

const Table = {
  SelfRegistration,
  AddUser,
  EditUserInfo,
  ForgotPassword
};

export default Table;
