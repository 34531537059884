import React from 'react'
import './styles.css'

const AppFooter = () => {
  return (
    <div className='footer'>
      <div className='footerInner'>
        <p className="mb-0" style={{textAlign: 'center'}}>
          All Rights Reserved © Smart Works International {new Date().getFullYear()}
          <br />
          <a href="https://smartworksintl.com/" target="_blank" rel="noopener noreferrer">
            smartworksintl.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default AppFooter
