import actions from "./actions";

const initialState = {
  fullname: "",
  name: "",
  role: "",
  email: "",
  phoneNumber: "",
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  avatar: "",
  id: "",
  loading: false,
  users: []
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.FETCH_USERS: {
      const { id } = action.payload[0]
      const usersCopy = state.users
      const filter = usersCopy.filter((item) => item.id !== id).concat(action.payload[0])
      const copy = { ...state, users: [...filter] }
      return { ...state, ...copy }
    }
    case actions.ADD_USER: {
      const { firstName, lastName } = action.payload
      const updatedUsersList = state.users
        .concat({
          ...action.payload,
          fullname: `${firstName} ${lastName}`
        })
      const copy = { ...state, users: [...updatedUsersList] }
      return { ...state, ...copy }
    }
    case actions.EDIT_USER: {
      let updatedUsersList;
      let copy = {};

      if (state.id === action.payload.id) {
        updatedUsersList = 

        copy = { ...state, ...action.payload.updates }
      } else {
        updatedUsersList = state.users
          .map(singleUser => {
            if (singleUser.id === action.payload.id) {
  
              return {
                ...singleUser,
                ...action.payload.updates,
              }
            }
  
            return singleUser
          })

        copy = { ...state, users: [...updatedUsersList] }
      }

      return { ...state, ...copy }
    }
    default:
      return state;
  }
}
