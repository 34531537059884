import axios from "axios";
import { logout } from "../firebase";
import { notification } from "antd";

const axiosInstance = () => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_DB_PORT}/`,
    headers: {
      "Content-Type": "application/json",
      'content-Type': 'application/json',
      "Accept": "/",
      "Cache-Control": "no-cache",
    },
    credentials: "same-origin",
    withCredentials: true
  });

  // intercept the response of api endpoints
  instance.interceptors.response.use((response) => {

    return response;

    }, (error) => {

      if(error.response.status === 401) {

        logout();

        notification.warning({
          message: 'Cookie expired',
          description: 'Please login again to continue using the web application.'
        })

        window.location.href = `${window.location.origin}/#/Auth/login/`;

        error.data.success = false;

        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export default axiosInstance;