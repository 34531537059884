import 'antd/dist/antd.min.css';
import "antd/lib/style/index.less";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createHashHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import createSagaMiddleware from "redux-saga";
import reducers from "../src/sdk/redux/reducers";
import sagas from "./sdk/redux/sagas";
import { routerMiddleware } from 'connected-react-router';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [sagaMiddleware, routeMiddleware];

const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares))
);
sagaMiddleware.run(sagas);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { store, history };
